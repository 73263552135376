import { SecurityClient, LoginDto, SetUserRolesCommand, RequestPasswordResetCommand,RequestPasswordResetForEmailCommand, Result, ResetPasswordCommand } from "./api.generated.clients";
import useSecurity from "../utils/useSecurity"
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

interface ISecurityApi {
    Login: (email: string, password: string) => Promise<boolean>,
    Logout: () => Promise<boolean>,
    LoadSession: ()=> Promise<void>,
    SetRoles: (roles: string[], userId: string)=> Promise<void>,
    RequestResetPassword: (userId: string) => Promise<Result>,
    RequestResetPasswordForEmail: (email: string) => Promise<Result>,
    ResetPassword: (request: ResetPasswordCommand) => Promise<Result>
}

export default function () : ISecurityApi {
    const client = new SecurityClient();
    const queryClient = useQueryClient();
    const security = useSecurity();

    const login = async (email: string, password: string): Promise<boolean> => {        
        try{
            await client.login({email : email, password: password} as LoginDto);
            
            return true;
        }
        catch{
            return false;
        }
    }

    const logout = async (): Promise<boolean> => {
        try {
            await client.logout();
            return true;
        }
        catch {
            return false;
        }
    }

    const loadSession = async () : Promise<void> => {
        const session = await client.session();
        if(!session.roles)
                throw Error('no roles returned from login');
        security.SetRoles(session.roles);
    }

    const setRolesMutation = useMutation({
        mutationFn: async (props: {roles: string[], userId: string}) =>{
            return client.roles({userId: props.userId, roles: props.roles } as SetUserRolesCommand);
        },
        onSuccess: (data, variables)=>{
            queryClient.invalidateQueries({queryKey: ['user', variables.userId]});
            queryClient.invalidateQueries({queryKey: ['users']})
        }
    })

    const setRoles = (roles: string[], userId: string) : Promise<void> => {
        return setRolesMutation.mutateAsync({roles, userId});
    }

    const requestPasswordReset = (userId: string) => {
        return client.requestresetpassword({ userId } as RequestPasswordResetCommand);
    }

    const requestPasswordResetForEmail = (email: string) => {
        return client.requestpasswordresetforemail({ email } as RequestPasswordResetForEmailCommand);
    }

    const resetPassword = (request : ResetPasswordCommand) => {
        return client.resetpassword(request);
    }
    

    return {
        Login: login,
        Logout: logout,
        SetRoles: setRoles,
        LoadSession: loadSession,
        RequestResetPassword: requestPasswordReset,
        RequestResetPasswordForEmail: requestPasswordResetForEmail,
        ResetPassword: resetPassword
    };
}

export type { ResetPasswordCommand }