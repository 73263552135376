import React, { PropsWithChildren } from 'react';
import Stack from '@mui/joy/Stack';
import Box from '@mui/joy/Box';
import { Sheet } from '@mui/joy';

interface IOpenProps{
    isOpen: boolean,
}

interface IDrawerProps extends IOpenProps, PropsWithChildren {
    openWidth: string,
    closedWidth: string,
    scroll?: string | undefined,
    height?: string | undefined,
    borderRight?: string | undefined
    styles?: React.CSSProperties | undefined
}

const Drawer: React.FC<IDrawerProps> = ({ children, isOpen, openWidth, closedWidth, scroll, height, borderRight, styles }) => {
    const drawerStyle = {
        width: isOpen ? openWidth : closedWidth,
        transition: !isOpen ? `width 300ms ease-out 500ms` : `width 300ms ease-out`,
        paddingLeft: '15px',
        overflowY: scroll ? scroll : null,
        overflowX: 'hidden',
        height: height ? height : null,
        maxHeight: '100%',
        borderRight: borderRight ? borderRight : null,
        // backgroundColor: 'blue',
        ...(styles ?? {})
    }

    return (
        <Sheet variant='solid' color='primary' sx={drawerStyle} >
            {children}
        </Sheet>
    )
}

export default Drawer;

export type { IOpenProps, IDrawerProps}