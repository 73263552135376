import React from 'react';
import { useSaveOpportunityAction, SaveOpportunityActionCommand, SustainableOpportunityActionDto} from '../../api/useSustainability';
import { ProjectCollaboratorDto, RiskActionDto } from '../../api/api.generated.clients';
import { CollaboratorDto } from '../../api/useCollaborators';
import { Modal, ModalDialog, DialogTitle, Stack, Input, Textarea, Button, Typography, Select, Option, ListDivider, IconButton } from '@mui/joy';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useGetProject, ProjectDto } from '../../api/useProjects';
import InviteCollaboratorButton from '../Collaborators/InviteCollaboratorButton';
import DeleteCollaboratorDialog from '../Collaborators/DeleteCollaboratorDialog';
import moment from 'moment';
import 'moment/locale/en-au'
import { Delete } from '@mui/icons-material';

export interface IEditOpportunityAction {
    projectId: string,
    opportunityAction: SustainableOpportunityActionDto,
    onClose: () => void,
    isOpen: boolean
}

export default function (props: IEditOpportunityAction) {
    const [saveActionCommand, setSaveActionCommand] = React.useState<SaveOpportunityActionCommand>({})
    const [project, setProject] = React.useState<ProjectDto>();
    const getProjectQuery = useGetProject(props.projectId ?? '');
    const [showInviteCollaboratorDialog, setShowInviteCollaboratorDialog] = React.useState<boolean>(false);
    const [collaboratorToDelete, setCollaboratorToDelete] = React.useState<ProjectCollaboratorDto>();
    const saveOpportunityAction = useSaveOpportunityAction();

    React.useEffect(() => {
        if (props.opportunityAction)
            setSaveActionCommand({ ...props.opportunityAction })
    }, [props.opportunityAction])

    React.useEffect(() => {
        if (!getProjectQuery.isPending && !getProjectQuery.isError && getProjectQuery.data)
            setProject(getProjectQuery.data);
    }, [getProjectQuery.isPending, getProjectQuery.data])

    function onSave() {
        async function doWork() {
            await saveOpportunityAction(saveActionCommand);
        }
        doWork();

        props.onClose();
        setSaveActionCommand({});
    }

    function onAssignUser(id: string | null) {
        var isCollaborator = project?.collaborators?.some(x => x.id === id) ?? false;
        setSaveActionCommand({ ...saveActionCommand, assignedUserId: id || undefined, assignedToCollaborator: isCollaborator })
    }

    function onDeleteCollaborator(x: ProjectCollaboratorDto): void {
        setCollaboratorToDelete(x)
    }

    return <Modal open={props.isOpen} onClose={() => props.onClose()} >
        <ModalDialog sx={{ width: '800px' }}>

            <Stack direction={'column'} spacing={2} sx={{ overflow: 'auto' }}>

                <Stack spacing={2} direction={'column'} pr={5}>
                    <Typography mb={5} level={'h2'}>Sustainability Opportunity Action</Typography>

                    <Typography>Action Summary</Typography>
                    <Input value={saveActionCommand.actionTitle ?? ' '} onChange={(e) => setSaveActionCommand({ ...saveActionCommand, actionTitle: e.target.value })} />
                    <Typography>Action Notes</Typography>
                    <Textarea placeholder='Enter Action Details' minRows={4} maxRows={10} value={saveActionCommand.actionNotes ?? ' '} onChange={(event) => setSaveActionCommand({ ...saveActionCommand, actionNotes: event.target.value })}></Textarea>
                    <Typography>Action Owner</Typography>
                    <Select placeholder='Select an Owner' value={saveActionCommand.assignedUserId  ?? ' '} onChange={(e, val) => onAssignUser(val)}>
                        <InviteCollaboratorButton projectId={project?.id ?? ''} />
                        <ListDivider>Project Users</ListDivider>
                        {project && project.projectUsers?.map(x => <Option value={x.userId}>{x.fullName} ({x.email})</Option>)}
                        <ListDivider>External Collaborators</ListDivider>
                        {project && project.collaborators?.map(x => <Option  value={x.id}>
                            <Stack direction='row' justifyContent={'space-between'} sx={{ width: '100%'}}>
                                {x.name} ({x.email})
                                <IconButton onClick={()=> onDeleteCollaborator(x)}><Delete /></IconButton>
                            </Stack>
                        </Option>)}
                    </Select>
                    <Typography>Due Date</Typography>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-au'}>
                        <DatePicker slotProps={{ textField: { size: 'small' } }} value={moment(saveActionCommand.dueOn)} onChange={e => setSaveActionCommand({ ...saveActionCommand, dueOn: moment(e) ?? undefined })} />
                    </LocalizationProvider>

                    <Stack direction='row' justifyContent={'space-between'} sx={{ width: '100%' }}>
                        <Button onClick={() => props.onClose()} variant='soft'>Cancel</Button>
                        <Button onClick={() => onSave()}>Submit</Button>
                    </Stack>
                </Stack>
            </Stack>
            <DeleteCollaboratorDialog projectId={props.projectId} onClose={()=>setCollaboratorToDelete(undefined)} collaborator={collaboratorToDelete!} isOpen={!!collaboratorToDelete}/>
        </ModalDialog>
    </Modal>
}