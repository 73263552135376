import React from 'react';
import { MapMarkerDto, RiskItemDto } from '../../api/api.generated.clients';
import { Recycling, Delete } from '@mui/icons-material';
import { Card, Stack, Typography, CardActions, IconButton } from '@mui/joy';
import { IOverlayProps } from '../Annotations/IOverlayProps';



export default function (props: IOverlayProps) {
    
    return <Card variant='solid' invertedColors size={'sm'} sx={{ ...props.styles ?? {}, minWidth: '100px', maxWidth: '300px' }}>
        <Stack direction={'column'}>
            <Stack direction={'column'}>
                <Stack direction={'row'}>
                    <Typography startDecorator={<Recycling color='warning' />} level={'h3'}>RefNum: {props.title || '<No RefNum>'}</Typography>
                </Stack>
            </Stack>
            <CardActions buttonFlex={'1 0 100px'} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton variant='soft' onClick={() => props.onDeleteMarker?.()}><Delete /></IconButton>
            </CardActions>
        </Stack>
    </Card>
}