import React from 'react';
import { useGetUser, GetUserDto } from '../../api/useUserAdmin';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import useSecurityApi from '../../api/useSecurityApi';


export interface IConfirmDeleteDialog{
    userId: string,    
    isOpen: boolean,
    onClose: ()=>void
}

export default function(props: IConfirmDeleteDialog){
    const { RequestResetPassword } = useSecurityApi();
    const [user, setUser] = React.useState<GetUserDto | undefined>();
    const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
    const [showError, setShowError] = React.useState<boolean>(false);
    const [requested, setRequested] = React.useState<boolean>();
    const userQuery = useGetUser(props.userId);

    React.useEffect(()=>{
        if(userQuery.data)
            setUser(userQuery.data);

    },[userQuery.isPending, userQuery.data])

    function onRequest(){
        setRequested(true);//disable submit button
        const doWork = async ()=>{
            var result = await RequestResetPassword(props.userId);
            if(result.succeeded)
                setShowSuccess(true);
            else
                setShowError(true);
        }

        doWork();
    }

    function onClose(){
        setShowError(false);
        setShowSuccess(false);
        setRequested(false);
        props.onClose();
    }

    function SuccessMode(){
        return (
            <Stack direction={'column'} gap={3}>
                    <Typography>An email has been sent to {user?.firstName} {user?.lastName} containing instructions on how to reset their password</Typography>
                    <Button onClick={onClose}>Ok</Button>
                </Stack>
        )
    }

    function ErrorMode(){
        return (
            <Stack direction={'column'} gap={3}>
                    <Typography>An error has occurred. Please contact support.</Typography>
                    <Button onClick={onClose}>Ok</Button>
                </Stack>
        )
    }

    return (
        <Modal open={props.isOpen} onClose={onClose}>
            <ModalDialog>
                { showSuccess && <SuccessMode/>}
                { showError && <ErrorMode/> }
                {!showSuccess && !showError && 
                <Stack direction={'column'} gap={3}>
                    <Typography>Do you want to reset the password for {user?.firstName} {user?.lastName}?</Typography>
                    <Typography>{user?.email}</Typography>
                    <Button disabled={requested} onClick={()=> onRequest()}>Reset Password</Button>
                </Stack>}
            </ModalDialog>
        </Modal>
    )
}