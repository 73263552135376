import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Result, 
    SustainabilityClient, 
    ResultOfEmissionsMaterialsDto, 
    EmissionsMaterialsDto, 
    ResultOfSustainableOpportunityDto, 
    SaveOpportunityCommand,
    ResultOfEmissionsMaterialCalculationDataDto,
    SustainableOpportunityDto,
    AddFilePointToOpportunityCommand,
    AddMapPointToOpportunityCommand,
    SaveOpportunityActionCommand,
    SustainableOpportunityActionDto
 } from './api.generated.clients';

export interface IQueryResponse<T> {
    isPending: boolean,
    data: T | undefined,
    error: Error
}

const client = new SustainabilityClient();


const useGetEmissionsMaterials = () => {
    return useQuery({ queryKey: ['emissions-materials'], queryFn: () => client.categories() });
}

const useGetCalculationData = (db: string, cat: string, item: string, material: string, quantity? : number | undefined)=>{
    return useQuery({ 
        queryKey: ['emissions-calculation-data', db, cat, item, material, quantity], 
        queryFn: () => client.calculationdata(db, cat, item, material, quantity),
        enabled: db !== '' && cat !== '' && item !== '' && material != ''
    })
}

const useGetOpportunityList = (projectId: string) => {
    return useQuery({
        queryKey: ['sustainability-opportunities', projectId],
        queryFn: ()=> client.opportunities(projectId)
    })
}

const useGetOpportunity = (opportunityId: string) => {
    return useQuery({
        queryKey: ['sustainability-opportunity', opportunityId],
        queryFn: ()=> client.opportunityGet(opportunityId),
        enabled: !!opportunityId && opportunityId !== ''
    })
}

const useSaveSusainabilityOpportunity = () =>{
    const queryClient = useQueryClient();
    const saveOpportunity = useMutation({
        mutationFn: (cmd : SaveOpportunityCommand) => client.opportunityPost(cmd),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: ['sustainability-opportunity']})
            queryClient.invalidateQueries({queryKey: ['sustainability-opportunities' ]})
        }
    });

    return saveOpportunity.mutateAsync;
}

const useDeleteSustainabilityOpportunity = () => {
    const queryClient = useQueryClient();
    const deleteOpportunityMutation = useMutation({
        mutationFn: (opportunityId : string) => client.opportunityDelete(opportunityId),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['sustainability-opportunity']})
            queryClient.invalidateQueries({queryKey: ['sustainability-opportunities']})
        }
    })

    return deleteOpportunityMutation.mutateAsync;
}


const useAddMapPoint = () => {
    const queryClient = useQueryClient();
    const addMapPointMutation = useMutation({
        mutationFn: (cmd: AddMapPointToOpportunityCommand) => client.mapmarkerPost(cmd),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['sustainability-opportunity', variables.opportunityId] });
            queryClient.invalidateQueries({ queryKey: ['sustainability-opportunities'] });
        }
    });
    return addMapPointMutation.mutateAsync;
}



const useAddFilePoint = () => {
    const queryClient = useQueryClient();
    const addFilePointMutation = useMutation({
        mutationFn: (cmd: AddFilePointToOpportunityCommand) => client.filemarkerPost(cmd),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['sustainability-opportunity', variables.opportunityId] });
            queryClient.invalidateQueries({ queryKey: ['sustainability-opportunities'] });
        }
    })
    return addFilePointMutation.mutateAsync;
}



const useDeleteFilePoint = () => {
    const queryClient = useQueryClient();
    const deleteFilePointMutation = useMutation({
        mutationFn: ({ opportunityId, filepointId }: { opportunityId: string, filepointId: string }) => client.filemarkerDelete(opportunityId, filepointId),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['sustainability-opportunity', variables.opportunityId] });
            queryClient.invalidateQueries({ queryKey: ['sustainability-opportunities'] });
        }
    });
    return deleteFilePointMutation.mutateAsync;
}



const useDeleteMapPoint = () => {
    const queryClient = useQueryClient();
    const deleteMapPointMutation = useMutation({
        mutationFn: ({ opportunityId, markerId }: { opportunityId: string, markerId: string }) => client.mapmarkerDelete(opportunityId, markerId),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['sustainability-opportunity', variables.opportunityId] });
            queryClient.invalidateQueries({ queryKey: ['sustainability-opportunities'] });
        }
    });
    return deleteMapPointMutation.mutateAsync;
}

const useSaveOpportunityAction = () => {
    const queryClient = useQueryClient();
    const saveOpportunityActionMutation = useMutation({
        mutationFn: (cmd: SaveOpportunityActionCommand) => client.actionPost(cmd),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['sustainability-opportunity', variables.opportunityId] });
            queryClient.invalidateQueries({ queryKey: ['sustainability-opportunities', variables.phaseId] });
        }
    });
    return saveOpportunityActionMutation.mutateAsync;
}

const useDeleteOpportunityAction = () => {
    const queryClient = useQueryClient();
    const deleteOpportunityActionMutation = useMutation({
        mutationFn: ({ opportunityId, actionId }: { opportunityId: string, actionId: string }) => client.actionDelete(opportunityId, actionId),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['sustainability-opportunity', variables.opportunityId] });
            queryClient.invalidateQueries({ queryKey: ['sustainability-opportunities'] });
        }
    });
    return deleteOpportunityActionMutation.mutateAsync;
}

export type {ResultOfEmissionsMaterialsDto, EmissionsMaterialsDto, ResultOfSustainableOpportunityDto, SaveOpportunityCommand, ResultOfEmissionsMaterialCalculationDataDto, SustainableOpportunityDto, AddFilePointToOpportunityCommand,
    AddMapPointToOpportunityCommand, SustainableOpportunityActionDto, SaveOpportunityActionCommand}
export {useGetEmissionsMaterials, useGetCalculationData, useSaveSusainabilityOpportunity, useGetOpportunity, useGetOpportunityList, useDeleteSustainabilityOpportunity, useAddFilePoint, useAddMapPoint, useDeleteFilePoint, useDeleteMapPoint, useSaveOpportunityAction, useDeleteOpportunityAction}