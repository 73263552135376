import React from 'react';
import useSecurityApi from '../api/useSecurityApi';
import { useNavigate } from "react-router-dom";
import Card from '@mui/joy/Card';
import Input from '@mui/joy/Input';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/joy/FormControl';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import FormHelperText from '@mui/joy/FormHelperText';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/joy/styles';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginBottom: '10px'
}))

export default function () {
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [failFlag, setFailFlag] = React.useState<boolean>(false);
    const accounts = useSecurityApi();
    const navigate = useNavigate();

    const onLogin = () => {
        async function doLogin() {
            const loggedIn = await accounts.Login(email, password);
            if (loggedIn) {
                navigate('/home');
            }else{
                setFailFlag(true);
            }
        }
        doLogin();
    }

    const onFormSubmit = (e: any) => {
        onLogin();
        e.preventDefault();
        e.stopPropagation();
    }



    return (
        <Card sx={{ width: '300px', height: '300px', alignSelf: 'center' }} variant='outlined'>
            <form onSubmit={onFormSubmit}>
                <Stack direction={'column'}>
                    <StyledFormControl>
                        <FormLabel>Email</FormLabel>
                        <Input autoFocus required value={email} onChange={e => setEmail(e.target.value)} />
                    </StyledFormControl>
                    <StyledFormControl>
                        <FormLabel>Password</FormLabel>
                        <Input type='password' required value={password} onChange={e => setPassword(e.target.value)} />
                    </StyledFormControl>
                    <Stack>
                        <StyledFormControl error={failFlag}>
                            {failFlag && <FormHelperText >
                                <InfoOutlined />
                                We were unable to log you in
                            </FormHelperText>}
                            <Button type='submit'>Login</Button>
                        </StyledFormControl>
                        <StyledFormControl>
                            <Button type='reset' variant='soft'>Cancel</Button>
                        </StyledFormControl>
                        <Link to='requestpasswordreset' component={RouterLink}>Forgot Password</Link>
                    </Stack>
                </Stack>
            </form>
        </Card>
    )
}