import React from 'react';
import { ProjectPhaseDto } from '../../api/useProjects';
import { Button, FormControl, FormLabel, IconButton, Input, Stack, Typography } from '@mui/joy';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { SaveProjectPhaseCommand } from '../../api/api.generated.clients';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';

export interface IManagePhasesViewProps {
    projectId?: string,
    phases: ProjectPhaseDto[],
    onUpdated: (phases: ProjectPhaseDto[]) => void,
    onDelete:(phase: ProjectPhaseDto) => void
}

export default function (props: IManagePhasesViewProps) {
    const [selectedPhase, setSelectedPhase] = React.useState<ProjectPhaseDto>();
    const [phases, setPhases] = React.useState<ProjectPhaseDto[]>([]);

    // React.useEffect(() => {
    //     setPhases(props.phases ?? [])
    // }, [props.phases])

    function onAddPhase() {
        console.log('add phase')
        const newPhase = { startDate: moment(), dueDate: moment() } as ProjectPhaseDto;
        const phaseList = [...(phases ?? []), newPhase];
        setSelectedPhase(newPhase);
        setPhases(phaseList)
        props.onUpdated(phaseList)
    }

    function onRemovePhase(e: React.MouseEvent<HTMLAnchorElement>, phase: ProjectPhaseDto){
        console.log('remove phase')
        e.preventDefault();
        e.stopPropagation();
        const updatedPhases = [...phases.filter(p=>p!==phase)]
        setSelectedPhase(undefined);
        setPhases(updatedPhases)
        props.onUpdated(updatedPhases);
        props.onDelete(phase);
    }

    function onNameUpdated(e: React.ChangeEvent<HTMLInputElement>) {
        console.log('name update')
        const updatedPhase = { ...selectedPhase, phaseName: e.target.value };
        const updatedPhases = [...phases.filter(x => x !== selectedPhase), updatedPhase]
        setPhases([...updatedPhases])
        setSelectedPhase(updatedPhase);
        props.onUpdated([...updatedPhases])
    }

    function onStartDateUpdated(date: Moment | null) {
        console.log('start date update')
        const updatedPhase = { ...selectedPhase, startDate: date } as ProjectPhaseDto;
        const updatedPhases = [...phases.filter(x => x !== selectedPhase), updatedPhase]
        setPhases([...updatedPhases])
        setSelectedPhase(updatedPhase);
        props.onUpdated([...updatedPhases])
    }

    function onDueDateUpdated(date: Moment | null) {
        console.log('due date update')
        const updatedPhase = { ...selectedPhase, dueDate: date } as ProjectPhaseDto;
        const updatedPhases = [...phases.filter(x => x !== selectedPhase), updatedPhase]
        setPhases([...updatedPhases])
        setSelectedPhase(updatedPhase);
        props.onUpdated([...updatedPhases])
    }

    React.useEffect(() => {
        if (!!props.phases && props.phases.length > 0) {
            setPhases([
                ...props.phases.map(p => {
                    return {
                        ...p,
                        startDate: !!p.startDate ? moment(p.startDate) : moment(),
                        dueDate: !!p.dueDate ? moment(p.dueDate) : moment()
                    }
                })
            ])
        }
        // else{
        //     setPhases([])
        // }
    }, [props.phases])

    return (
        <Stack sx={{ width: '100%', height: '100%' }} spacing={2}>
            <Stack direction='row'>
                <Button onClick={() => onAddPhase()}>Add Phase</Button>
            </Stack>
            <Stack direction='row' sx={{ width: '100%', height: '100%' }} spacing={2}>
                <List sx={{ height: '100%', p: '5px', overflow:'auto', maxWidth: '200px' }} variant='outlined'>
                    {phases.length === 0 ? <ListItem> -- empty --</ListItem> : phases.sort((a,b)=>{return (a?.startDate?.hours() ?? 0) - (b.startDate?.hours() ?? 0)}).map((x, i) => {
                        return <ListItem key={i}>
                            <ListItemButton onClick={() => setSelectedPhase(x)}>
                                <Stack direction='row' justifyContent={'space-between'} sx={{width:'100%'}}>
                                    <Typography>{x.phaseName}</Typography>
                                    <ListItemDecorator><IconButton onClick={(e)=>onRemovePhase(e, x)}><DeleteIcon/></IconButton></ListItemDecorator>
                                </Stack>
                            </ListItemButton>
                        </ListItem>
                    })}
                </List>
                <Stack sx={{ flexGrow: 1 }} spacing={2}>
                    {selectedPhase && <><FormControl>
                        <FormLabel>Phase Name</FormLabel>
                        <Input value={selectedPhase?.phaseName ?? ''} onChange={onNameUpdated} required />
                    </FormControl>
                        <FormControl>
                            <FormLabel>Start Date</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker value={selectedPhase.startDate ?? moment()} onChange={onStartDateUpdated} />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Due Date</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker value={selectedPhase.dueDate ?? moment()} onChange={onDueDateUpdated} />
                            </LocalizationProvider>
                        </FormControl></>}

                </Stack>
            </Stack>
        </Stack>
    )
}