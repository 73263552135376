import React from 'react';
import { RiskItemDto } from '../../api/api.generated.clients';
import { Button, ButtonGroup, Select, Option, Chip, IconButton, Box, Stack, Checkbox } from '@mui/joy';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import Edit from '@mui/icons-material/Edit';
import EditRiskForm from './EditRiskForm';
import { GetDisciplines, GetPhases, GetSeverities, GetRiskSeverityLabel } from '../Risks/RiskHelpers';
import { ProjectDto} from '../../api/useProjects';
import styled from '@emotion/styled';

export interface IRiskFilterToolbarProps {
    project: ProjectDto,
    risks: RiskItemDto[],
    onAddMarker?: ((targetRisk: RiskItemDto) => void) | undefined,
    onRisksSet: (risks: RiskItemDto[]) => void,
    disabled: boolean
}
export default function (props: IRiskFilterToolbarProps) {
    const [selectedRisks, setSelectedRisks] = React.useState<string[]>([]);
    const [riskToEdit, setRiskToEdit] = React.useState<RiskItemDto | undefined>();
    const [phaseFilter, setPhaseFilter] = React.useState<string[]>([]);
    const [disciplineFilter, setDisciplineFilter] = React.useState<string[]>([]);
    const [severityFilter, setSeverityFilter] = React.useState<string[]>([]);
    const [risksToDisplay, setRisksToDisplay] = React.useState<RiskItemDto[]>([]);
    // const phases = GetPhases();
    const disciplines = GetDisciplines();
    const severities = GetSeverities();

    

    React.useEffect(() => {
        const relevantRisks = selectedRisks.length > 0 ? risksToDisplay.filter(x => selectedRisks.some(s => s === x.id)) : risksToDisplay;
        props.onRisksSet(relevantRisks);
    }, [selectedRisks, risksToDisplay]);

    React.useEffect(() => {
        var filtered = [...props.risks];
        if (phaseFilter.length > 0) {
            filtered = filtered.filter(f => phaseFilter.some(x => x === f.phaseId));
        }
        if (disciplineFilter.length > 0) {
            filtered = filtered.filter(f => disciplineFilter.some(x => x === f.discipline));
        }

        if(severityFilter.length > 0){
            filtered = filtered.filter(f=> severityFilter.some(x=>x === GetRiskSeverityLabel(f)))
        }

        setRisksToDisplay(filtered);
    }, [props.risks, JSON.stringify(phaseFilter), JSON.stringify(disciplineFilter), JSON.stringify(severityFilter)])

    function handleRiskFilterChange(
        event: React.SyntheticEvent | null,
        newValue: string[] | null,
    ) {
        setSelectedRisks(newValue ?? []);
    };

    function onAddRiskMarker(event: React.MouseEvent<HTMLAnchorElement>, risk: RiskItemDto) {
        event.preventDefault();
        props.onAddMarker?.call({}, risk);
    }

    function onEditRisk(event: React.MouseEvent<HTMLAnchorElement>, risk: RiskItemDto) {
        event.preventDefault();
        setRiskToEdit(risk);
    }

    return (
        <Stack direction='row' >
            <Select 
                disabled={props.disabled}
                placeholder='Filter by Severity'
                sx={{ minWidth: '200px', marginLeft: '5px' }}
                multiple
                value={severityFilter}
                onChange={(e, vals: string[]) => setSeverityFilter(vals)}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                        {selected.map((selectedOption) => (
                            <Chip variant="solid" color="primary">
                                {selectedOption.label}
                            </Chip>
                        ))}
                    </Box>
                )}>
                <Button variant='soft' size='sm' onClick={() => setSeverityFilter([])}>Clear Selection</Button>
                {severities.map(p =>
                    <Option value={p}>
                        <Stack sx={{ width: '100%' }} direction='row' justifyContent={'space-between'}>
                            <Checkbox checked={severityFilter.some(x => x === p)}></Checkbox>
                            ({risksToDisplay.filter(x=>p===GetRiskSeverityLabel(x)).length}) {p}
                        </Stack>
                    </Option>)}
            </Select>

            {/* <Select
                disabled={props.disabled}
                placeholder='Filter by Phases'
                sx={{ minWidth: '200px', marginLeft: '5px' }}
                multiple
                value={phaseFilter}
                onChange={(e, vals: string[]) => setPhaseFilter(vals)}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                        {selected.map((selectedOption) => (
                            <Chip variant="soft" color="primary">
                                {selectedOption.label}
                            </Chip>
                        ))}
                    </Box>
                )}>
                <Button variant='soft' size='sm' onClick={() => setPhaseFilter([])}>Clear Selection</Button>
                {props.project.phases!.map(p =>
                    <Option value={p.id}>
                        <Stack sx={{ width: '100%' }} direction='row' justifyContent={'space-between'}>
                            <Checkbox checked={phaseFilter.some(x => x === p.id)}></Checkbox>
                            ({risksToDisplay.filter(x=>p.id===x.phaseId).length}) {p.phaseName}
                        </Stack>
                    </Option>)}
            </Select> */}
            
            <Select 
                disabled={props.disabled}
                placeholder='Filter by Discipline'
                sx={{ minWidth: '200px', marginLeft: '5px' }}
                multiple
                value={disciplineFilter}
                onChange={(e, vals: string[]) => setDisciplineFilter(vals)}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                        {selected.map((selectedOption) => (
                            <Chip variant="solid" color="primary">
                                {selectedOption.label}
                            </Chip>
                        ))}
                    </Box>
                )}>
                <Button variant='soft' size='sm' onClick={() => setDisciplineFilter([])}>Clear Selection</Button>
                {disciplines.map(p =>
                    <Option value={p.value}>
                        <Stack sx={{ width: '100%' }} direction='row' justifyContent={'space-between'}>
                            <Checkbox checked={disciplineFilter.some(x => x === p.value)}></Checkbox>
                            ({risksToDisplay.filter(x=>p.value===x.discipline).length}) {p.Label}
                        </Stack>
                    </Option>)}
            </Select>
            
            <Select
                disabled={props.disabled}
                multiple
                value={selectedRisks}
                placeholder='Risks'
                sx={{ minWidth: '200px', marginLeft: '5px' }}
                onChange={handleRiskFilterChange}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                        {selected.map((selectedOption) => (
                            <Chip variant="solid" color="primary">
                                {selectedOption.label}
                            </Chip>
                        ))}
                    </Box>
                )}>
                <Button variant='soft' size='sm' onClick={() => setSelectedRisks([])}>Clear Selection</Button>
                {risksToDisplay.map(r => {
                    return <Option value={r.id}>
                        <Stack sx={{ width: '100%' }} direction={'row'} justifyContent={'space-between'}>
                            <Checkbox checked={selectedRisks.some(x => x === r.id)}></Checkbox>
                            {r.referenceNumber}
                            <ButtonGroup size='sm'>
                                <IconButton disabled={!props.onAddMarker} onClick={(e) => onAddRiskMarker(e, r)}>
                                    <AddLocationAltIcon />
                                </IconButton>
                                <IconButton onClick={(e) => onEditRisk(e, r)}>
                                    <Edit />
                                </IconButton>
                            </ButtonGroup>
                        </Stack>
                    </Option>
                })
                }
            </Select>
            <EditRiskForm isOpen={!!riskToEdit} onClose={() => setRiskToEdit(undefined)} riskId={riskToEdit?.id ?? ''} isNew={false} projectId={props.project.id ?? ''} />
        </Stack>
    )
}