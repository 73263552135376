import React, { ComponentType, ReactElement } from 'react';
import { ProjectDto } from '../../../api/api.generated.clients';
import { IToolbarProps } from './FlexiFileView';
import { Card, Tabs, TabList, Stack, Tab, TabPanel, Typography, Select, Option } from '@mui/joy';
import MapView from '../MapView';
import RisksView from './RisksView';
import RiskToolbar from './RiskToolbar';

export interface IProjectFeatureLayout{
    project: ProjectDto,
    renderFileView: () => ReactElement,
    renderDataView: () => ReactElement,
    renderMapView: () => ReactElement,
    onPhaseIdChanged: (phaseId:string) => void
}

export default function ProjectFeatureLayout(props: IProjectFeatureLayout){
    const [selectedPhaseId, setSelectedPhaseId] = React.useState<string>();

    React.useEffect(()=>{
        props.onPhaseIdChanged?.(selectedPhaseId ?? '')
    },[selectedPhaseId])

    React.useEffect(()=>{
        setSelectedPhaseId(props.project?.phases?.[0].id)
    },[props.project])

    return (
        <Card variant='soft' color='neutral' sx={{ padding: '2px', height: '100%' }}>
                    <Tabs variant='outlined' color='primary' sx={{ height: '100%' }} >
                        <TabList sx={{ pb: '5px' }}>
                            <Stack direction={'row'} alignItems={'center'} sx={{ mr: '30px', ml: '10px' }}>
                                <Typography sx={{ mr: '10px' }}>Project Phase:</Typography>
                                <Select defaultValue={props.project?.phases?.[0].id ?? ''} 
                                    disabled={!props.project || props.project.phases?.length === 0}
                                    value={selectedPhaseId ?? ''}
                                    onChange={(e, v) => setSelectedPhaseId(v!)}>
                                    {props.project.phases?.map(x => <Option value={x.id}>{x.phaseName}</Option>)}
                                </Select>
                            </Stack>
                            <Tab variant='outlined' color='primary'><Typography level={'title-sm'}>File View</Typography></Tab>
                            <Tab variant='outlined' color='primary'><Typography level={'title-sm'}>Register</Typography></Tab>
                            <Tab variant='outlined' color='primary'><Typography level={'title-sm'}>Map View</Typography></Tab>
                        </TabList >
                        <TabPanel variant='outlined' color='primary' value={0} >
                            {props.renderFileView()}
                        </TabPanel>
                        <TabPanel variant='outlined' color='primary' value={1}>
                            {props.renderDataView()}
                        </TabPanel>
                        <TabPanel variant='outlined' color='primary' value={2}>
                            {props.renderMapView()}
                        </TabPanel>
                    </Tabs>
                </Card>
    )
}