import React from 'react';
import { ProjectDto } from '../../api/api.generated.clients';
import { Button, ButtonGroup, Select, Option, Chip, IconButton, Box, Stack, Checkbox } from '@mui/joy';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import Edit from '@mui/icons-material/Edit';
import EditOpportunityForm from './EditOpportunityForm';
import { GetDisciplines, GetPhases } from '../Sustainability/SustainabilityHelpers';
import styled from '@emotion/styled';
import { ResultOfSustainableOpportunityDto, SustainableOpportunityDto}  from '../../api/useSustainability';

export interface IOpportunityFilterToolbarProps {
    project: ProjectDto,
    opportunities: SustainableOpportunityDto[],
    onAddMarker?: ((targetOpportunity: SustainableOpportunityDto) => void) | undefined,
    onOpportunitiesSet: (opportunities: SustainableOpportunityDto[]) => void,
    disabled: boolean
}
export default function (props: IOpportunityFilterToolbarProps) {
    const [selectedOpportunities, setSelectedOpportunities] = React.useState<string[]>([]);
    const [OpportunityToEdit, setOpportunityToEdit] = React.useState<SustainableOpportunityDto | undefined>();
    const [phaseFilter, setPhaseFilter] = React.useState<string[]>([]);
    const [disciplineFilter, setDisciplineFilter] = React.useState<string[]>([]);
    const [OpportunitiesToDisplay, setOpportunitiesToDisplay] = React.useState<SustainableOpportunityDto[]>([]);
    // const phases = GetPhases();
    const disciplines = GetDisciplines();

    

    React.useEffect(() => {
        const relevantOpportunities = selectedOpportunities.length > 0 ? OpportunitiesToDisplay.filter(x => selectedOpportunities.some(s => s === x.id)) : OpportunitiesToDisplay;
        props.onOpportunitiesSet(relevantOpportunities);
    }, [selectedOpportunities, OpportunitiesToDisplay]);

    React.useEffect(() => {
        var filtered = [...props.opportunities];
        if (phaseFilter.length > 0) {
            filtered = filtered.filter(f => phaseFilter.some(x => x === f.phaseId));
        }
        if (disciplineFilter.length > 0) {
            filtered = filtered.filter(f => disciplineFilter.some(x => x === f.discipline));
        }

        

        setOpportunitiesToDisplay(filtered);
    }, [props.opportunities, JSON.stringify(phaseFilter), JSON.stringify(disciplineFilter)])

    function handleRiskFilterChange(
        event: React.SyntheticEvent | null,
        newValue: string[] | null,
    ) {
        setSelectedOpportunities(newValue ?? []);
    };

    function onAddOpportunityMarker(event: React.MouseEvent<HTMLAnchorElement>, opportunity: SustainableOpportunityDto) {
        event.preventDefault();
        props.onAddMarker?.call({}, opportunity);
    }

    function onEditOpportunity(event: React.MouseEvent<HTMLAnchorElement>, opportunity: SustainableOpportunityDto) {
        event.preventDefault();
        setOpportunityToEdit(opportunity);
    }

    return (
        <Stack direction='row' >           
            <Select
                disabled={props.disabled}
                placeholder='Filter by Phases'
                sx={{ minWidth: '200px', marginLeft: '5px' }}
                multiple
                value={phaseFilter}
                onChange={(e, vals: string[]) => setPhaseFilter(vals)}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                        {selected.map((selectedOption) => (
                            <Chip variant="soft" color="primary">
                                {selectedOption.label}
                            </Chip>
                        ))}
                    </Box>
                )}>
                <Button onClick={() => setPhaseFilter([])}>Clear Selection</Button>
                {props.project?.phases?.map(p =>
                    <Option value={p.id}>
                        <Stack sx={{ width: '100%' }} direction='row' justifyContent={'space-between'}>
                            <Checkbox checked={phaseFilter.some(x => x === p.id)}></Checkbox>
                            ({OpportunitiesToDisplay.filter(x=>p.id===x.phaseId).length}) {p.phaseName}
                        </Stack>
                    </Option>)}
            </Select>
            
            <Select 
                disabled={props.disabled}
                placeholder='Filter by Discipline'
                sx={{ minWidth: '200px', marginLeft: '5px' }}
                multiple
                value={disciplineFilter}
                onChange={(e, vals: string[]) => setDisciplineFilter(vals)}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                        {selected.map((selectedOption) => (
                            <Chip variant="soft" color="primary">
                                {selectedOption.label}
                            </Chip>
                        ))}
                    </Box>
                )}>
                <Button onClick={() => setDisciplineFilter([])}>Clear Selection</Button>
                {disciplines.map(p =>
                    <Option value={p.value}>
                        <Stack sx={{ width: '100%' }} direction='row' justifyContent={'space-between'}>
                            <Checkbox checked={disciplineFilter.some(x => x === p.value)}></Checkbox>
                            ({OpportunitiesToDisplay.filter(x=>p.value===x.discipline).length}) {p.Label}
                        </Stack>
                    </Option>)}
            </Select>
            
            <Select
                disabled={props.disabled}
                multiple
                value={selectedOpportunities}
                placeholder='Opportunities'
                sx={{ minWidth: '200px', marginLeft: '5px' }}
                onChange={handleRiskFilterChange}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                        {selected.map((selectedOption) => (
                            <Chip variant="soft" color="primary">
                                {selectedOption.label}
                            </Chip>
                        ))}
                    </Box>
                )}>
                <Button onClick={() => setSelectedOpportunities([])}>Clear Selection</Button>
                {OpportunitiesToDisplay.map(r => {
                    return <Option value={r.id}>
                        <Stack sx={{ width: '100%' }} direction={'row'} justifyContent={'space-between'}>
                            <Checkbox checked={selectedOpportunities.some(x => x === r.id)}></Checkbox>
                            {r.referenceNumber}
                            <ButtonGroup size='sm'>
                                <IconButton disabled={!props.onAddMarker} onClick={(e) => onAddOpportunityMarker(e, r)}>
                                    <AddLocationAltIcon />
                                </IconButton>
                                <IconButton onClick={(e) => onEditOpportunity(e, r)}>
                                    <Edit />
                                </IconButton>
                            </ButtonGroup>
                        </Stack>
                    </Option>
                })
                }
            </Select>
            <EditOpportunityForm 
                isNew={!(OpportunityToEdit?.id) }
                projectId={props.project.id ?? ''} 
                phaseId={OpportunityToEdit?.phaseId ?? ''}
                isOpen={!!OpportunityToEdit} 
                onClose={() => setOpportunityToEdit(undefined)} 
                opportunityId={OpportunityToEdit?.id ?? ''} />
        </Stack>
    )
}