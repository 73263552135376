import React from 'react';
import './App.css';
import { CssVarsProvider } from '@mui/joy/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GlobalContextProvider } from './utils/GlobalContextProvider';
import Home from './pages/Home';
import ProjectsList2 from './pages/ProjectsList2';
import ProjectView2 from './pages/ProjectView2';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './layouts/Layout';
import Login from './pages/Login';
import AdminSettings from './pages/AdminSettings';
import NavPaneLayout from './layouts/NavPaneLayout';
import Theme from './themes/colors';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as extendMaterialTheme,
  THEME_ID
} from "@mui/material/styles";
import PasswordReset from './pages/PasswordReset';
import RequestPasswordReset from './pages/RequestPasswordReset';
import moment from 'moment';

const materialTheme = extendMaterialTheme();

function App() {
  moment.locale('en-au');
  const queryClient = new QueryClient({
    defaultOptions:{
      queries: {
        refetchOnWindowFocus: false,
        staleTime: Infinity
      }
    }
  });

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <GlobalContextProvider defaultUserId={undefined} >
          <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
            <CssVarsProvider theme={Theme}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <BrowserRouter>
                  <Routes>
                    <Route path='/' element={<Layout />} >
                      <Route index element={<Login />} />
                    </Route>
                    <Route path='requestpasswordreset' element={<Layout/>}>
                      <Route index element={<RequestPasswordReset/>}/>                      
                    </Route>
                    <Route path='/passwordreset' element={<Layout/>}>
                      <Route index element={<PasswordReset/>}/>
                    </Route>
                    <Route path='/home' element={<NavPaneLayout />}>
                      <Route index element={<Home />} />
                    </Route>                    
                    <Route path='/projects' element={<NavPaneLayout />}>
                      <Route index element={<ProjectsList2 />} />
                    </Route>                    
                    <Route path='/project' element={<NavPaneLayout />}>
                      <Route path={':id'} element={<ProjectView2 />} />
                    </Route>
                    <Route path='/adminsettings' element={<NavPaneLayout />}>
                      <Route index element={<AdminSettings />} />
                    </Route>
                  </Routes>
                </BrowserRouter>
              </LocalizationProvider>
            </CssVarsProvider>
          </MaterialCssVarsProvider>
        </GlobalContextProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
