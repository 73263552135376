import React from 'react';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { Box, Typography } from '@mui/joy';
import { useGetUser, useUpdateUser, GetUserDto, UpdateUserCommand } from '../../api/useUserAdmin';


export interface IEditUserFormProps{
    isOpen: boolean,
    userId: string,
    onClose: ()=>void
}

export default function (props: IEditUserFormProps) {
    const [firstName, setFirstName] = React.useState<string>();
    const [lastName, setLastName] = React.useState<string>();
    const [validationErrors, setValidationErrors] = React.useState<(string | undefined)[]>();
    const getUserQuery = useGetUser(props.userId);
    const updateUser = useUpdateUser();

    React.useEffect(()=>{
        if(!getUserQuery.isPending && getUserQuery.data){
            setFirstName(getUserQuery.data.firstName);
            setLastName(getUserQuery.data.lastName);
        }
    }, [getUserQuery.isPending, getUserQuery.data])

    const onFormSubmitted = (event: React.FormEvent<HTMLFormElement>)=>{
        event.preventDefault();

        if(!firstName || !lastName){
            setValidationErrors(['You must enter your name']);
            return;
        }
        
        const command = {
            userId: props.userId,
            firstName: firstName,
            lastName: lastName            
        } as UpdateUserCommand;

        const doWork = async ()=>{
            try{
                await updateUser(command);
            }
            catch(e : any){
                setValidationErrors(['unable to update user'])
            }
            onClose();
        }
        doWork();        
    }    

    function onClose(){
        props.onClose();
        setFirstName(undefined);
        setLastName(undefined);
    }

    return (
        // <Stack sx={{ width: '100%', height: '100%' }}>
            <Modal open={props.isOpen} onClose={onClose}>
                <ModalDialog>
                    <DialogTitle>Create new user</DialogTitle>
                    <DialogContent><Typography>Fill in the information of the project.</Typography></DialogContent>
                    <form onSubmit={onFormSubmitted}>
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>First Name</FormLabel>
                                <Input value={firstName} onChange={(e)=>setFirstName(e.target.value)} autoFocus required />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Last Name</FormLabel>
                                <Input required value={lastName} onChange={(e)=> setLastName(e.target.value)}/>
                            </FormControl>                            
                            {validationErrors?.map(x=> <Typography>{x}</Typography>)}
                            <Button type="submit">Submit</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        // </Stack> 
    )
}