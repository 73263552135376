import { Box, Button, DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, ModalDialog, Stack, Typography } from '@mui/joy';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import React from 'react';
import GoogleMap, { IGoogleMapRef } from '../Maps/GoogleMap';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment';
import { AssignProjectUsersCommand, ProjectDto, ProjectUserDto, SaveProjectCommand } from '../../api/api.generated.clients';
import { useSaveProject, useAssignProjectUsers } from '../../api/useProjects';
import AssignProjectUsersForm from './AssignProjectUsersForm';

export interface IProjectDetailsFormProps{
    project? : ProjectDto,
    onUpdated: (cmd: SaveProjectCommand) => void
}

export default function(props: IProjectDetailsFormProps){
    const [startDate, setStartDate] = React.useState<Moment | null>(moment());
    const [saveCommand, setSaveCommand] = React.useState<SaveProjectCommand>({startDate: moment(), ...props.project})

    React.useEffect(()=>{
        const command = {
            startDate: startDate                
        } as SaveProjectCommand;

        props.onUpdated({...saveCommand, startDate: command.startDate});                
    },[saveCommand, startDate])

    React.useEffect(()=>{
        if(props.project?.startDate){
            setStartDate(moment(props.project?.startDate))
        }
    },[props.project])
    
    

    
    return (
        // <Stack sx={{ width: '100%', height: '100px' }}>            
            <Stack spacing={2}>
                <FormControl>
                    <FormLabel>Reference Number</FormLabel>
                    <Typography>{saveCommand.referenceNumber}</Typography>
                </FormControl>
                <FormControl>
                    <FormLabel>Company</FormLabel>
                    <Input value={saveCommand.company} onChange={(e) => setSaveCommand({...saveCommand, company: e.target.value})} autoFocus />
                </FormControl>
                <FormControl>
                    <FormLabel>Direct Client</FormLabel>
                    <Input value={saveCommand.directClient} onChange={(e) => setSaveCommand({...saveCommand, directClient: e.target.value})} autoFocus />
                </FormControl>
                <FormControl>
                    <FormLabel>Ultimate Client</FormLabel>
                    <Input value={saveCommand.ultimateClient} onChange={(e) => setSaveCommand({...saveCommand, ultimateClient: e.target.value})} autoFocus />
                </FormControl>
                <FormControl>
                    <FormLabel>Project Manager</FormLabel>
                    <Input value={saveCommand.projectManager} onChange={(e) => setSaveCommand({...saveCommand, projectManager: e.target.value})} autoFocus />
                </FormControl>
                <FormControl>
                    <FormLabel>Project Director</FormLabel>
                    <Input value={saveCommand.projectDirector} onChange={(e) => setSaveCommand({...saveCommand, projectDirector: e.target.value})} autoFocus />
                </FormControl>
                <FormControl>
                    <FormLabel>Project Name</FormLabel>
                    <Input required value={saveCommand.projectName} onChange={(e) => setSaveCommand({...saveCommand, projectName: e.target.value})} />
                </FormControl>
                <FormControl>
                    <FormLabel>Start Date</FormLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker value={startDate} onChange={e => setStartDate(e)} />
                    </LocalizationProvider>
                </FormControl>       
            </Stack>
        // </Stack>
    )
}