import React from 'react';
import useSecurityApi from '../../api/useSecurityApi';
import { useGetUser } from '../../api/useUserAdmin';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';


interface IEditRolesProps {
    userId: string,
    onUpdated?: () => void,
    isOpen: boolean,
    onClose: () => void
}

interface ICanSaveRoles {
    onSave: () => void
}
const EditRoles = React.forwardRef((props: IEditRolesProps, ref: React.ForwardedRef<ICanSaveRoles>) => {
    const securityApi = useSecurityApi();
    const userQuery = useGetUser(props.userId);

    const [selectedRole, setSelectedRole] = React.useState<string>('ProjectUser');

    React.useEffect(() => {
        if (!userQuery.isPending && userQuery.data?.roles && userQuery.data?.roles.length > 0) {
            setSelectedRole(userQuery.data.roles[0]);
        }
    }, [userQuery.data])

    // React.useImperativeHandle(ref, () => ({
    function onSave() {
        if (selectedRole) {
            securityApi.SetRoles([selectedRole], props.userId).then(() => {
                if (props.onUpdated)
                    props.onUpdated();
            });
        }
        props.onClose();
    }
    // }))

    

    return (
        <Modal open={props.isOpen} onClose={props?.onClose}>
            <ModalDialog>
                <Stack direction={'column'} gap={3}>
                    <Stack gap={3}>
                        <Typography>Role Name:</Typography>
                        <Select defaultValue='ProjectUser' value={selectedRole} onChange={(event, value) => setSelectedRole(value || 'ProjectUser')}>
                            <Option value='OrganizationAdmin'>Organization Admin</Option>
                            <Option value='ProjectsAdministrator'>Projects Administrator</Option>
                            <Option value='ProjectUser'>Project User</Option>
                        </Select>                        
                    </Stack>                    
                </Stack>
                <Stack>
                    <Button onClick={() => onSave()}>Save</Button>
                </Stack>
            </ModalDialog>
        </Modal>
    )
})



interface ISaveRolesButtonProps {
    onClick: () => void
}



export default EditRoles;
export type { ICanSaveRoles };