import React from 'react';
import { useGetProject } from '../../api/useProjects';
import Table from '@mui/joy/Table';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import { Tooltip } from '@mui/joy';
// import EditRiskForm from './EditRiskForm';
import EditOpportunityForm from './EditOpportunityForm';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import AddItemToMapDialog from '../Maps/AddItemToMapDialog';
import { Add } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IMapPoint } from '../Maps/GoogleMap';
// import DeleteRiskDialog from './DeleteRiskDialog';
import DeleteOpportunityDialog from './DeleteOpportunityDialog';
import MapIcon from '@mui/icons-material/Map';
import { MapMarkerDto, AddMapPointToOpportunityCommand, ProjectDto, SustainableOpportunityDto } from '../../api/api.generated.clients';
import { useGetOpportunityList, useDeleteSustainabilityOpportunity, useAddMapPoint } from '../../api/useSustainability';
import StaticMapDialog from '../Maps/StaticMapDialog';
// import { LikelihoodScore, ConsequenceScore } from './ScoreEvaluation';
// import { GetRiskColour, GetRiskSeverityLabel } from './RiskHelpers';
import Typography from '@mui/joy/Typography';
// import RiskFilterSelectToolbar from './RiskFilterSelectToolbar';
import OpportunityFilterSelectToolbar from './OpportunityFilterSelectToolbar';


export interface IOpportunitiesViewProps {
    project: ProjectDto,
    phaseId: string
}

export default function (props: IOpportunitiesViewProps) {
    const [filteredOpportunities, setFilteredOpportunities] = React.useState<SustainableOpportunityDto[]>([])
    const [selectedOpportunity, setSelectedOpportunity] = React.useState<SustainableOpportunityDto>();
    const [showEditForm, setShowEditForm] = React.useState<boolean>(false);
    const [showDeleteOpportunityForm, setShowDeleteOpportunityForm] = React.useState<boolean>(false);
    // const [project, setProject] = React.useState<ProjectDto>();
    const [isMapDialogOpen, setIsMapDialogOpen] = React.useState<boolean>(false);
    const addMapMarker = useAddMapPoint();
    const opportunitiesQuery = useGetOpportunityList(props.project.id ?? '')
    const [opportunities, setOpportunities] = React.useState<SustainableOpportunityDto[]>([])
    const deleteOpportunity = useDeleteSustainabilityOpportunity();

    React.useEffect(()=>{
        if(!opportunitiesQuery.isPending && !opportunitiesQuery.isError && opportunitiesQuery.data.succeeded)
            setOpportunities(opportunitiesQuery.data.data ?? [])
    },[opportunitiesQuery.isPending, opportunitiesQuery.data, opportunitiesQuery.isError])

    
    

    function onEditOpportunity(opportunity: SustainableOpportunityDto) {
        setSelectedOpportunity(opportunity);
        setShowEditForm(true);
    }

    function onCloseEditOpportunityForm() {
        setSelectedOpportunity(undefined);
        setShowEditForm(false);
    }

    function onDeleteOpportunity(opportunity: SustainableOpportunityDto) {
        setSelectedOpportunity(opportunity);
        setShowDeleteOpportunityForm(true);
    }

    function onCloseDeleteOpportunityForm() {
        setShowDeleteOpportunityForm(false);
        setSelectedOpportunity(undefined);
    }

    const tableStyle = {
        minWidth: 0,
        tableLayout: 'auto',
        width: '100%',
        '& tr > td': {
            verticalAlign: 'top'
        },
        '& tr > td:first-of-type': {
            position: 'sticky',
            left: 0,
            backgroundColor: 'white'
        },
        '& thead th:nth-of-type(4)': {
            minWidth: '100px',
            maxWidth: '100px'
        },
        '& thead th:nth-of-type(6)': {
            minWidth: '400px',
            maxWidth: '400px'
        },
        '& thead th:nth-of-type(7)': {
            minWidth: '500px',
            maxWidth: '500px'
        },
        // '& thead th:nth-of-type(11)': {
        //     minWidth: '500px',
        //     maxWidth: '500px'
        // },
        // '& thead th:nth-of-type(12)': {
        //     minWidth: '500px',
        //     maxWidth: '500px'
        // }
    }

    function onSaveMapMarker(opportunity: SustainableOpportunityDto, point: IMapPoint) {
        setSelectedOpportunity({
            ...selectedOpportunity,
            mapMarkers: [
                ...(selectedOpportunity?.mapMarkers || []),
                { centreLatitude: point.latitude, centreLongitude: point.longitude } as MapMarkerDto]
        });

        const newMarker = {
            opportunityId: opportunity.id,
            latitude: point.latitude,
            longitude: point.longitude
        } as AddMapPointToOpportunityCommand;

        addMapMarker(newMarker);
    }

    function onCloneOpportunity(opportunity: SustainableOpportunityDto){
        const clone = {...opportunity};
        clone.id = undefined;
        clone.referenceNumber = undefined;
        setSelectedOpportunity(clone);
        setShowEditForm(true);
    }

    // function onViewRiskMap(risk: RiskItemDto) {
    //     setSelectedRisk(risk);
    //     setIsMapDialogOpen(true);
    // }

    // function onMapDialogClose(): void {
    //     setIsMapDialogOpen(false);
    //     setSelectedRisk(undefined);
    // }

    if (!props.project)
        return <>no project</>

    return (
        <Stack direction={'column'} spacing={1} sx={{ width: 'calc(100% - 32px)', maxHeight: '100%' }} >
            <Stack direction='row'>
                <ButtonGroup>
                    <Button onClick={() => setShowEditForm(true)}>Add Sustainability Opportunity</Button>
                </ButtonGroup>
                <OpportunityFilterSelectToolbar project={props.project!} opportunities={opportunities} disabled={false} onOpportunitiesSet={(ops) => setFilteredOpportunities(ops)} />
            </Stack>
            <Box sx={{ overflow: 'auto', maxWidth: '100%' }}>
                <Table borderAxis='both' sx={tableStyle}>
                    <thead>
                        <tr>
                            <th></th>
                            <th><Typography level='title-md'>Reference Number</Typography></th>
                            <th><Typography level='title-md'>Phase</Typography></th>
                            <th><Typography level='title-md'>Discipline</Typography></th>
                            <th><Typography level='title-md'>Initiative Category</Typography></th>
                            <th><Typography level='title-md'>Initiative Element</Typography></th>
                            <th><Typography level='title-md'>Initiative Description</Typography></th>
                            <th><Typography level='title-md'>Initiative Owner</Typography></th>
                            <th><Typography level='title-md'>Status</Typography></th>
                            <th><Typography level='title-md'>Qualitative Benefit</Typography></th>
                            <th><Typography level='title-md'>Quantitative Benefit</Typography></th>
                            <th><Typography level='title-md'>Dollar Value Benefit</Typography></th>
                            <th><Typography level='title-md'>Emmissions Database</Typography></th>
                            <th><Typography level='title-md'>Emissions Category</Typography></th>
                            <th><Typography level='title-md'>Emissions Item</Typography></th>
                            <th><Typography level='title-md'>Emissions Material</Typography></th>
                            <th><Typography level='title-md'>Emissions Unit</Typography></th>
                            <th><Typography level='title-md'>Emissions Quantity</Typography></th>
                            <th><Typography level='title-md'>Total Carbon Emissions</Typography></th>
                            <th><Typography level='title-md'>ISC Category</Typography></th>
                            <th><Typography level='title-md'>ISC Credit</Typography></th>
                            <th><Typography level='title-md'>ISC Credit Name</Typography></th>
                            <th><Typography level='title-md'>Comments</Typography></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOpportunities?.map((x, i) => {
                            return (<tr key={i}>
                                <td>
                                    <ButtonGroup size='sm'>
                                        <Tooltip title='Edit Opportunity'><IconButton size='sm' onClick={() => onEditOpportunity(x)}><Edit /></IconButton></Tooltip>
                                        <Tooltip title='Delete Opportunity'><IconButton size='sm' onClick={() => onDeleteOpportunity(x)}><Delete /></IconButton></Tooltip>
                                        <Tooltip title='Clone Opportunity'><IconButton size='sm' onClick={()=> onCloneOpportunity(x)}><ContentCopyIcon/></IconButton></Tooltip>
                                        <Tooltip title='Add Map Marker to Opportunity'><AddItemToMapDialog
                                            initialLatitude={props.project?.centreLatitude || 0}
                                            initialLongitude={props.project?.centreLongitude || 0}
                                            initialZoom={props.project?.zoomLevel || 0}
                                            message={`Select Location to add a Map Marker for Opportunity: ${x.referenceNumber}`}
                                            title={x.referenceNumber}
                                            onSave={(m) => onSaveMapMarker(x, m)} /></Tooltip>
                                        {/* <IconButton size='sm' onClick={() => onViewRiskMap(x)}><MapIcon /></IconButton> */}
                                    </ButtonGroup>
                                </td>
                                <td><Typography level='body-md'>{x.referenceNumber}</Typography></td>
                                <td><Typography level='body-md'>{props.project?.phases?.find(p=>p.id === x.phaseId)?.phaseName}</Typography></td>
                                <td><Typography level='body-md'>{x.discipline}</Typography></td>
                                <td><Typography level='body-md'>{x.initiativeCategory}</Typography></td>
                                <td><Typography level='body-md'>{x.initiativeElement}</Typography></td>
                                <td><Typography level='body-md'>{x.initiativeDescription}</Typography></td>
                                <td><Typography level='body-md'>{x.initiativeOwner}</Typography></td>
                                <td><Typography level='body-md'>{x.status}</Typography></td>
                                <td><Typography level='body-md'>{x.qualitativeBenefit}</Typography></td>
                                <td><Typography level='body-md'>{x.quantitativeBenefit}</Typography></td>
                                <td><Typography level='body-md'>{x.valueDollarBenefit}</Typography></td>
                                <td><Typography level='body-md'>{x.emissionsDatabase}</Typography></td>
                                <td><Typography level='body-md'>{x.emissionsCategory}</Typography></td>
                                <td><Typography level='body-md'>{x.emissionsItem}</Typography></td>
                                <td><Typography level='body-md'>{x.emissionsMaterial}</Typography></td>
                                <td><Typography level='body-md'>{x.emissionsUnit}</Typography></td>
                                <td><Typography level='body-md'>{x.emissionsQuantity}</Typography></td>
                                <td><Typography level='body-md'>{x.totalCarbonEmissions} {x.carbonEmissionsUnit}</Typography></td>
                                <td><Typography level='body-md'>{x.iscCategory}</Typography></td>
                                <td><Typography level='body-md'>{x.iscCredit}</Typography></td>
                                <td><Typography level='body-md'>{x.iscCreditName}</Typography></td>
                                <td><Typography level='body-md'>{x.comments}</Typography></td>
                            </tr>)
                        })}
                    </tbody>
                </Table>
            </Box>
            <EditOpportunityForm
                isNew={!(selectedOpportunity?.id) }
                isOpen={showEditForm}
                onClose={() => onCloseEditOpportunityForm()}
                opportunityId={selectedOpportunity?.id ?? ''} 
                phaseId={selectedOpportunity?.phaseId ?? ''}
                projectId={props.project.id ?? ''}/>
            <DeleteOpportunityDialog isOpen={showDeleteOpportunityForm}
                onClose={onCloseDeleteOpportunityForm}
                opportunity={selectedOpportunity!}
                phaseId={selectedOpportunity?.phaseId ?? ''} />
            {/*<StaticMapDialog centreLatitude={project?.centreLatitude ?? 0}
                centreLongitude={project?.centreLongitude ?? 0}
                title={`Risk Reference#: ${project?.referenceNumber ?? ''}`}
                markers={selectedRisk?.mapMarkers?.map(m => ({ latitude: m.centreLatitude ?? 0, longitude: m.centreLongitude ?? 0, colour: GetRiskColour(selectedRisk) })) ?? []}
                zoomLevel={project?.zoomLevel ?? 1}
                isOpen={isMapDialogOpen}
                onClose={() => onMapDialogClose()} /> */}
        </Stack>
    )
}