import React from 'react';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel from '@mui/joy/FormLabel';
import Tabs from '@mui/joy/Tabs';
import Tab from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import styled from '@emotion/styled';
import Card from '@mui/joy/Card';
import AddProjectButton from '../components/Projects/AddProjectButton';
import ProjectList from '../components/Projects/ProjectList2';

const PageContainer = styled(Stack)(({ }) => ({
    height: '100%',
    width: '100%',
    padding: '20px',
    overflow: 'hidden',
    maxHeight: 'calc(100vh - 40px)'
}))

const PageCard = styled(Card)(({ theme }) => ({
    width: 'calc(100% - 40px)',
    height: 'calc(100% - 40px)'
}))

const HeaderStack = styled(Stack, { label: 'header-stack' })(({ theme }) => ({
    height: '40px'
}))

const FillStack = styled(Stack)(({ theme }) => ({
    width: '100%',
    height: '100%'
}))

const FillTabs = styled(Tabs, { label: 'fill-tabs' })(({ theme }) => ({
    width: 'calc(100% - 32px)',
    height: 'calc(100% - 40px)',
    maxHeight: '100%'
}))

const FillPanel = styled(TabPanel, { label: 'fill-panel' })(({ theme }) => ({
    width: '100%',
    height: 'calc(100% - 53px)',
    overflowX: 'hidden'
}))


export default function () {

    return (
        <PageContainer direction={'column'} alignItems={'center'} justifyContent={'start'}>
            <PageCard>
                {/* <HeaderStack direction={'row'} justifyContent={'flex-end'}>Some Buttons</HeaderStack> */}
                <FillTabs>
                    <TabList>
                        <Tab>Favourites</Tab>
                    </TabList>
                    <FillPanel value={0}>
                        <FillStack>
                            <AddProjectButton />
                            <ProjectList />
                        </FillStack>
                    </FillPanel>
                </FillTabs>
            </PageCard>
        </PageContainer>
    )
}