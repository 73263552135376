import {
    FileSystemClient,
    SaveImageAnnotationCommand,
    SaveFolderCommand,
    FileDto,
    FolderDto,
    ResultOfFolderDto,
    ResultOfFileDto,
    FileParameter,
    SaveFileCommand,
    Result,
    FileType,
    SaveAnnotationCommand,
    ResultOfAnnotationDtoOf,
    ResultOfGuid,
    CreateFileUploadUrlCommand
} from "./api.generated.clients";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

export interface IQueryResponse<T> {
    isPending: boolean,
    data: T | undefined,
    error: Error
}

// interface IFileSystemApi {
//     SaveFolder: (command: SaveFolderCommand) => Promise<ResultOfFolderDto>,
//     UploadFile: (projectId: string, formFile: FileParameter, folderId: string | null | undefined) => Promise<ResultOfFileDto>
//     SaveFile: (command: SaveFileCommand) => Promise<ResultOfFileDto>,
//     GetFolder: (projectId: string, folderId: string | undefined, includeFile: boolean) => IQueryResponse<ResultOfFolderDto>,
//     DeleteFile: (projectId: string, fileId: string) => Promise<Result>,
//     DeleteFolder: (projectId: string, folderId: string) => Promise<Result>,
//     ReadFile: (projectId: string, fileId: string) => IQueryResponse<ResultOfFileDto>
//     SaveImageAnnotation: (command: SaveImageAnnotationCommand) => Promise<Result>,
//     SaveAnnotation: (command: SaveAnnotationCommand) => Promise<ResultOfGuid>,
//     GetFileAnnotations: (fileId: string) => IQueryResponse<ResultOfAnnotationDtoOf>,
//     DeleteAnnotation: (annotationId: string, fileId: string) => Promise<Result>
// }

const client = new FileSystemClient();

const useDeleteAnnotation = () => {
    const queryClient = useQueryClient();
    const deleteAnnotationMutation = useMutation({
        mutationFn: ({ annotationId, fileId }: { annotationId: string, fileId: string }) => client.annotationDelete(annotationId),
        onSuccess: (data, variables) => {

            queryClient.invalidateQueries({ queryKey: ['annotations', variables.fileId] })
        }
    });

    return deleteAnnotationMutation.mutateAsync;
}

const useSaveAnnotation = () => {
    const queryClient = useQueryClient();

    const saveAnnotationMutation = useMutation({
        mutationFn: (cmd: SaveAnnotationCommand) => client.annotationPost(cmd),
        onSuccess: (data, variables) => {

            queryClient.invalidateQueries({ queryKey: ['annotations', variables.fileId] });
        }
    });
    return saveAnnotationMutation.mutateAsync;
}

const useSaveImageAnnotation = () => {
    const queryClient = useQueryClient();

    const saveImageAnnotationMutation = useMutation({
        mutationFn: (cmd: SaveImageAnnotationCommand) => client.image(cmd),
        onSuccess: (data, variables) => {

            queryClient.invalidateQueries({ queryKey: ['file', variables.fileId] });
            queryClient.invalidateQueries({ queryKey: ['files'] });
            queryClient.invalidateQueries({ queryKey: ['folder', variables.projectId] });
        }
    });
    return saveImageAnnotationMutation.mutateAsync;
}

const useDeleteFile = () => {
    const queryClient = useQueryClient();

    const deleteFileMutation = useMutation({
        mutationFn: ({ projectId, fileId }: { projectId: string, fileId: string }) => client.fileDelete(projectId, fileId),
        onSuccess: (data, variables) => {

            queryClient.invalidateQueries({ queryKey: ['file', variables.fileId] });
            queryClient.invalidateQueries({ queryKey: ['files'] });
            queryClient.invalidateQueries({ queryKey: ['folder', variables.projectId] });
        }
    });
    return deleteFileMutation.mutateAsync;
}

const useDeleteFolder = () => {
    const queryClient = useQueryClient();

    const deleteFolderMutation = useMutation({
        mutationFn: ({ projectId, folderId }: { projectId: string, folderId: string }) => client.folderDelete(projectId, folderId),
        onSuccess: (data, variables) => {

            queryClient.invalidateQueries({ queryKey: ['folder', variables.projectId] });
            queryClient.invalidateQueries({ queryKey: ['folders'] });
        }
    });
    return deleteFolderMutation.mutateAsync;
}

const useSaveFolder = () => {
    const queryClient = useQueryClient();

    const saveFolderMutation = useMutation({
        mutationFn: (cmd: SaveFolderCommand) => client.folderPost(cmd),
        onSuccess: (data, variables) => {

            queryClient.invalidateQueries({ queryKey: ['folder', variables.phaseId] })
            queryClient.invalidateQueries({ queryKey: ['folders', variables.phaseId] })
        }
    });
    return saveFolderMutation.mutateAsync;
}

const useUploadFile = () => {
    const queryClient = useQueryClient();

    const createUploadMutation = useMutation({
        mutationFn: ({ projectId, phaseId, formFile, folderId, fileType }: { projectId: string, phaseId: string, formFile: FileParameter, folderId: string | null | undefined, fileType: string | null | undefined }) => client.createuploadurl({ fileName: formFile.fileName, folderId: folderId, projectId: projectId, phaseId: phaseId, contentType: fileType } as CreateFileUploadUrlCommand),
        onSuccess: (data, variables) => {
            if (data.succeeded) {
                const uploadRequest = {
                    method: 'PUT',
                    url: data.data?.uploadUrl,
                    body: variables.formFile.data,
                    headers: {
                        "Content-Type": variables.fileType
                    }
                } as RequestInit;
                fetch(data.data?.uploadUrl || '', uploadRequest);
                setTimeout(() => {
                    queryClient.invalidateQueries({ queryKey: ['file', data.data?.fileId] })
                    queryClient.invalidateQueries({ queryKey: ['files', variables.phaseId] })
                    queryClient.invalidateQueries({ queryKey: ['folder', variables.phaseId] })
                    queryClient.invalidateQueries({ queryKey: ['folders', variables.phaseId] })    
                }, 1000);
                
            }
        }
    })

    // const uploadFileMutation = useMutation({
    //     mutationFn: ({ projectId, formFile, folderId }: { projectId: string, formFile: FileParameter, folderId: string | null | undefined }) => client.upload(projectId, folderId, formFile),
    //     onSuccess: (data, variables) => {


    //         queryClient.invalidateQueries({ queryKey: ['file', data.data?.id] })
    //         queryClient.invalidateQueries({ queryKey: ['files', variables.projectId] })
    //         queryClient.invalidateQueries({ queryKey: ['folder', variables.projectId] })
    //     }
    // });
    return createUploadMutation.mutateAsync;
}

const useSaveFile = () => {
    const queryClient = useQueryClient();

    const saveFileMutation = useMutation({
        mutationFn: (command: SaveFileCommand) => client.filePost(command),
        onSuccess: (data, variables) => {
            setTimeout(() => {
                queryClient.invalidateQueries({ queryKey: ['file', data.data?.id] })
            queryClient.invalidateQueries({ queryKey: ['files', variables.phaseId] })
            queryClient.invalidateQueries({ queryKey: ['folder', variables.phaseId] })
            queryClient.invalidateQueries({ queryKey: ['folders', variables.phaseId] })
            }, 1000);
            
        }
    });
    return saveFileMutation.mutateAsync;
}

const useGetFolder = (phaseId: string, folderId: string | undefined) => {
    return useQuery({
        queryKey: ['folder', phaseId, folderId],
        queryFn: () => client.folderGet(phaseId, folderId),
        enabled: !!phaseId
    });
}

const useGetFile = (phaseId: string, fileId: string) => {
    return useQuery({
        queryKey: ['file', fileId],
        queryFn: () => client.fileGet(phaseId, fileId)
    })
}

const useGetAnnotations = (fileId: string) => {
    return useQuery({
        queryKey: ['annotations', fileId],
        queryFn: () => client.annotations(fileId)
    });
}


// SaveFolder: saveFolder,
// UploadFile: uploadFile,
// SaveFile: saveFile,
// GetFolder: getFolder,
// DeleteFile: deleteFile,
// DeleteFolder: deleteFolder,
// ReadFile: getFile,
// SaveImageAnnotation: saveImageAnnotation,
// SaveAnnotation: saveAnnotation,
// GetFileAnnotations: getAnnotations,
// DeleteAnnotation: deleteAnnotation

export { useSaveFolder, useUploadFile, useSaveFile, useGetFolder, useDeleteFile, useDeleteFolder, useGetFile, useSaveImageAnnotation, useSaveAnnotation, useGetAnnotations, useDeleteAnnotation }
export type { SaveFolderCommand, FileDto, FolderDto, ResultOfFolderDto, ResultOfFileDto, FileParameter, SaveImageAnnotationCommand }