import React, { useState } from 'react';
import { FileDto } from '../../api/useFileSystem';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import FormHelperText from '@mui/joy/FormHelperText';
import { RiskItemDto } from '../../api/api.generated.clients';
import { useDeleteRiskAction, RiskActionDto } from '../../api/useRisks';

export interface IDeleteRiskActionDialog{
    projectId: string,    
    action: RiskActionDto,
    isOpen: boolean,
    onClose: ()=>void
}

export default function(props: IDeleteRiskActionDialog){
    const [formError, setFormError] = useState<string>();
    const deleteRiskAction = useDeleteRiskAction();

    function onDelete(){    
        async function doWork(){
            if(props.action.actionId){
                await deleteRiskAction({riskId: props.action.riskId!, actionId: props.action.actionId!});
                props.onClose();
            }
            else    
                setFormError("The risk has not been saved");
        }
        doWork();
    }

    if(!props.action)
        return <></>

    return (
        <Modal open={props.isOpen} onClose={props.onClose}>
            <ModalDialog onClick={(e)=>e.stopPropagation()}>
                <Stack direction={'column'} gap={3}>
                    <Typography>Do you want to delete action item {props.action.actionTitle}?</Typography>                    
                    <Button onClick={()=> onDelete()}>Delete</Button>
                    {!!formError && <FormHelperText>
                                {formError}
                            </FormHelperText>}
                </Stack>
            </ModalDialog>
        </Modal>
    )
}