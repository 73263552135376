
function GetDisciplines(): IDiscipline[] {
    return [
        { value: "acoustic", Label: "Acoustic" },
        { value: "building architectural", Label: "Building Architectural" },
        { value: "bridges", Label: "Bridges" },
        { value: "drainage", Label: "Drainage" },
        { value: "environmental", Label: "Environmental" },
        { value: "electrical services", Label: "Electrical Services" },
        { value: "earthworks", Label: "Earthworks" },
        { value: "fencing", Label: "Fencing" },
        { value: "general", Label: "General" },
        { value: "geotechnical", Label: "Geotechnical" },
        { value: "land acquisitions", Label: "Land Acquisitions" },
        { value: "landscape design", Label: "Landscape Design" },
        { value: "local road works", Label: "Local Road Works" },
        { value: "maintenance", Label: "Maintenance" },
        { value: "n/a", Label: "N/A" },
        { value: "noise mitigation", Label: "Noise Mitigation" },
        { value: "public utility plant", Label: "Public Utility Plant" },
        { value: "pavement", Label: "Pavement" },
        { value: "property works", Label: "Property Works" },
        { value: "roads", Label: "Roads" },
        { value: "road furniture", Label: "Road Furniture" },
        { value: "road lighting", Label: "Road Lighting" },
        { value: "general reports", Label: "General Reports" },
        { value: "retaining walls", Label: "Retaining Walls" },
        { value: "shop drawings", Label: "Shop Drawings" },
        { value: "sketches", Label: "Sketches" },
        { value: "signs and lines", Label: "Signs and Lines" },
        { value: "specifications", Label: "Specifications" },
        { value: "structures", Label: "Structures" },
        { value: "systems", Label: "Systems" },
        { value: "traffic control plans", Label: "Traffic Control Plans" },
        { value: "typical details", Label: "Typical Details" },
        { value: "traffic", Label: "Traffic" },
        { value: "traffic staging", Label: "Traffic Staging" },
        { value: "temporary works", Label: "Temporary Works" },
        { value: "urban design", Label: "Urban Design" },
        { value: "miscellaneous", Label: "Miscellaneous" }
    ]
}

export interface IDiscipline {
    value: string,
    Label: string
}


function GetPhases(): IPhase[] {
    return [
        { value: 'design', Label: 'Design' },
        { value: 'construction', Label: 'Construction' },
        { value: 'maintainance', Label: 'Maintainance' },
        { value: 'operation', Label: 'Operation' },
        { value: 'demolition', Label: 'Demolition' },
        { value: 'other', Label: 'Other' },
    ]
}

export interface IPhase {
    value: string,
    Label: string
}

function GetSustainabilityCategories(): ISustainabilityCategory[] {
    return [
        { Category: 'Waste', Subcategories: ['Reducing waste', 'Reducing consumption', 'Recycling materials withing project', 'Recycling materials for other projects', 'Other'] },
        { Category: 'Materials', Subcategories: ['Reducing embodied carbon', 'Using renewable materials', 'Reducing material consumption', 'Using recycled materials', 'Other'] },
        { Category: 'Social', Subcategories: ['Improving social/community outcomes', 'Sustainable land use', 'Support local economy', 'Reducing travel distance/time for people', 'Other'] },
        { Category: 'Environmental' , Subcategories: ['Improving water quality and efficiency', 'Reduce noise pollution', 'Improve air quality', 'Reducing emissions', 'Reducing impact on nature and biodiversity', 'Cultural heritage protection and enhancement']},
        { Category: 'Resilience', Subcategories: ['Increasing resilience', 'Increasing longevity of assets', 'Design for climate change', 'Other'] },
        { Category: 'Energy', Subcategories: ['Reducing transport distance for materials', 'Fuel/Energy efficient transport', 'Use of renewable energy', 'Reducing operational energy use', 'Other'] },
        { Category: 'Economic', Subcategories:['Circular economy', 'Other'] }
    ]
}

export interface ISustainabilityCategory {
    Category: string,
    Subcategories: string[]
}

function GetIscCredits() : IscCategory[]{
    return [
        {CategoryName: "Place", Credits: [{Credit:'Pla-2', CreditName:'Urban and Landscape Design'}] },
        { CategoryName:'Leadership and Management',
          Credits: [
                {Credit:'Lea-1', CreditName: 'Integrating Sustainability'},
                {Credit:'Lea-2', CreditName: 'Risks & Opportunities'},
                {Credit:'Lea-3', CreditName: 'Knowledge Sharing'}
            ] 
        },
        { CategoryName:'Sustainable Procurement',
          Credits: [
                {Credit:'Spr-1', CreditName: 'Sustainable Procurement Strategy'},
                {Credit:'Spr-2', CreditName: 'Supplier Assessment and Selection'},
                {Credit:'Spr-3', CreditName: 'Contract and Supplier Management '}
            ] 
        },
        { CategoryName:'Resilience',
          Credits: [
                {Credit:'Res-1', CreditName: 'Climate and Natural Hazards Risks'},
                {Credit:'Res-2', CreditName: 'Resilience Planning'}
            ] 
        },
        { CategoryName:'Innovation',
          Credits: [
                {Credit:'Inn-1', CreditName: 'Innovation '}
            ] 
        },
        { CategoryName:'Options Assessment',
          Credits: [
                {Credit:'Ecn-1', CreditName: 'Options Assessment and Significant Decisions'}
            ] 
        },
        { CategoryName:'Energy and Carbon',
          Credits: [
                {Credit:'Ene-1', CreditName: 'Energy Efficiency and Carbon Reductions'},
                {Credit:'Ene-2', CreditName: 'Renewable Energy '},
                {Credit:'Ene-3', CreditName: 'Offsetting'}
            ] 
        },
        { CategoryName:'Environmental Impacts',
          Credits: [
                {Credit:'Env-1', CreditName: 'Receiving Water Quality'},
                {Credit:'Env-2', CreditName: 'Noise'},
                {Credit:'Env-3', CreditName: 'Vibration'},
                {Credit:'Env-4', CreditName: 'Air Quality'},
                {Credit:'Env-5', CreditName: 'Light Pollution'}
            ] 
        },
        { CategoryName:'Resource Efficiency and Management',
          Credits: [
                {Credit:'Rso-1', CreditName: 'Resource Strategy Development'},
                {Credit:'Rso-2', CreditName: 'Management of Contaminated Material'},
                {Credit:'Rso-3', CreditName: 'Management of Acid Sulfate Soil'},
                {Credit:'Rso-4', CreditName: 'Resource Recovery and Management'},
                {Credit:'Rso-5', CreditName: 'Adaptability and End of Life'},
                {Credit:'Rso-6', CreditName: 'Material Life Cycle Impact Measurement & Management'},
                {Credit:'Rso-7', CreditName: 'Sustainability Labelled Products and Supply Chains'}
            ] 
        },
        { CategoryName:'Water',
          Credits: [
                {Credit:'Wat-1', CreditName: 'Avoiding Water Use'},
                {Credit:'Wat-2', CreditName: 'Appropriate Use of Water Sources '}
            ] 
        },
        { CategoryName:'Ecology',
          Credits: [
                {Credit:'Eco-1', CreditName: 'Ecological Protection and Enhancement'}
            ] 
        },
        { CategoryName:'Stakeholder Engagement',
          Credits: [
                {Credit:'Sta-1', CreditName: 'Stakeholder Engagement Strategy'},
                {Credit:'Sta-2', CreditName: 'Stakeholder Engagement and Impacts'}
            ] 
        },
        { CategoryName:'Legacy',
          Credits: [
                {Credit:'Leg-1', CreditName: 'Leaving a Lasting Legacy'}
            ] 
        },
        { CategoryName:'Heritage',
          Credits: [
                {Credit:'Her-1', CreditName: 'Heritage Protection and Enhancement'}
            ] 
        },
        { CategoryName:'Workforce Sustainability',
          Credits: [
                {Credit:'Wfs-1', CreditName: 'Jobs, Skills and Workforce Planning'},
                {Credit:'Wfs-2', CreditName: 'Workplace Culture and Wellbeing'},
                {Credit:'Wfs-3', CreditName: 'Diversity and Inclusion'},
                {Credit:'Wfs-4', CreditName: 'Sustainable Site Facilities'}
            ] 
        },
    ]
}

export interface IIscCredit{
    Credit: string, 
    CreditName: string
}

export interface IscCategory{
    CategoryName: string,
    Credits: IIscCredit[]
}

export { GetDisciplines, GetPhases, GetSustainabilityCategories, GetIscCredits }