import React from "react";

export const UserContext = React.createContext<IUserSecurityContext>({} as IUserSecurityContext);

export interface IUserSecurityContext{
    UserId: string | undefined,
    SetUserId: (userId : string) => void,
    Roles: string[] | undefined,
    SetRoles: (roles : string[]) => void,
}

export interface IGlobalContextProviderProps{
    defaultUserId: string | undefined,
    children: JSX.Element
}

export const GlobalContextProvider = ({
  defaultUserId,
  children,
} : IGlobalContextProviderProps) => {
  const [userId, setUserId] = React.useState(defaultUserId);
  const [roles, setRoles] = React.useState<string[]>([]);

  return (    
    <UserContext.Provider value={{ UserId: userId, SetUserId: setUserId, Roles: roles, SetRoles: setRoles}}>
          {children}
    </UserContext.Provider>
  );
};
