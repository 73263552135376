import React, { PropsWithChildren } from 'react'
import { Outlet } from "react-router-dom";
import Sheet from '@mui/joy/Sheet';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import MenuIcon from '@mui/icons-material/Menu';
import ChecklistIcon from '@mui/icons-material/Checklist';
import IconButton from '@mui/joy/IconButton';
import InventoryIcon from '@mui/icons-material/Inventory';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Typography from '@mui/joy/Typography';
import { styled } from '@mui/joy';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from 'react-router-dom';
import Drawer, { IOpenProps } from '../components/Drawer/Drawer';
import LogoutIcon from '@mui/icons-material/Logout';
import useSecurityApi from '../api/useSecurityApi';
import { useNavigate } from "react-router-dom";

const DrawerItemContainer = styled(Stack)(({ theme }) => ({
    width: '100%',
    marginTop: '10px',
    marginBottom: '20px',
    justifyContent: 'flex-start',
    // color: 'var(--joy-palette-text-primary)',
    alignItems: 'center',
    flexDirection: 'row'
}));

const MenuItemText = styled(Typography, { shouldForwardProp: ((prop) => prop !== 'isOpen') })<IOpenProps>(({ theme, isOpen }) => ({
    opacity: isOpen ? 1 : 0,
    transition: !isOpen ? 'opacity 500ms ease-out' : 'opacity 500ms ease-out 200ms',
    overflow: 'hidden',
    marginLeft: '10px',
    noWrap: true,
    // color: 'var(--joy-palette-text-primary)'
}))

const MenuIconButton = styled(IconButton)(({ theme }) => ({
    // color: 'var(--joy-palette-text-icon)'
}));

//need to cast link as Box
//https://github.com/mui/material-ui/issues/38274#issuecomment-1661805628
const StyledLink = styled(Link, { shouldForwardProp: p => true })(({ theme }) => ({
    // color: 'inherit'
})) as typeof Box

export default function () {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [isLockedOpen, setLockedOpen] = React.useState<boolean>(true);
    const { Logout } = useSecurityApi();
    const navigate = useNavigate();

    const style = {
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    }

    const bodyStyle = {
        width: isOpen ? 'calc(100% - 215px)' : 'calc(100% - 75px)',
        transition: isOpen ? `width 300ms ease-out` : `width 300ms ease-out 500ms`,
    }

    function logout(){
        const doWork = async ()=>{
            await Logout();
            navigate({ pathname: '/'});
        }
        doWork();
    }

    return (
        <Sheet sx={style} variant='soft' >
            <Stack direction='row' sx={{ height: '100%', width: '100%', maxWidth: '100%' }} >
                <Sheet sx={{ borderRight: '1px solid var(--joy-palette-neutral-400)' }} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(isLockedOpen || false)}>
                    <Drawer isOpen={isOpen} openWidth='200px' closedWidth='60px' height='100%'>
                        <Stack direction={'column'} height={'100%'}>
                            <DrawerItemContainer>
                                <MenuIconButton variant='solid' color='primary' onClick={() => setLockedOpen(!isLockedOpen)}>
                                    <MenuIcon color='primary' sx={{ width: '32px', height: '32px' }} />
                                </MenuIconButton>
                                <MenuItemText variant='solid' color='primary' level='h2' noWrap isOpen={isOpen}>Kick Ass App</MenuItemText>
                            </DrawerItemContainer>

                            <DrawerItemContainer>
                                <StyledLink component={RouterLink} to='/home'>
                                    <ChecklistIcon color='primary' sx={{ width: '24px', height: '24px' }} />
                                    <MenuItemText variant='solid' color='primary' level='h3' isOpen={isOpen} noWrap>Home</MenuItemText>
                                </StyledLink>
                            </DrawerItemContainer>

                            <DrawerItemContainer>
                                <StyledLink component={RouterLink} to='/projects'>
                                    <InventoryIcon color='primary' sx={{ width: '24px', height: '24px' }} />
                                    <MenuItemText level='h3' variant='solid' color='primary' isOpen={isOpen} noWrap>Projects</MenuItemText>
                                </StyledLink>
                            </DrawerItemContainer>                            

                            <DrawerItemContainer>
                                <StyledLink component={RouterLink} to='/adminsettings'>
                                    <AdminPanelSettingsIcon color='primary' sx={{ width: '24px', height: '24px' }} />
                                    <MenuItemText level='h3' variant='solid' color='primary' isOpen={isOpen} noWrap>Admin Settings</MenuItemText>
                                </StyledLink>
                            </DrawerItemContainer>
                            <Stack sx={{ flexGrow: 1 }} direction={'column'} justifyContent={'flex-end'}>
                                <DrawerItemContainer>
                                    <IconButton color='primary' onClick={()=> logout()}>
                                        <LogoutIcon />
                                    </IconButton>
                                </DrawerItemContainer>
                            </Stack>
                        </Stack>
                    </Drawer>
                </Sheet>
                <Box sx={bodyStyle}>
                    <Outlet />
                </Box>
            </Stack>
        </Sheet>
    )
}
