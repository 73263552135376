import React from 'react';
import { useGetRisks, useAddMapPoint, RiskItemDto } from '../../../api/useRisks';
import { useGetProject } from '../../../api/useProjects';
import Table from '@mui/joy/Table';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import { Tooltip } from '@mui/joy';
import EditRiskForm from '../../Risks/EditRiskForm';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import AddItemToMapDialog from '../../Maps/AddItemToMapDialog';
import { Add } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IMapPoint } from '../../Maps/GoogleMap';
import DeleteRiskDialog from '../../Risks/DeleteRiskDialog';
import MapIcon from '@mui/icons-material/Map';
import { MapMarkerDto, AddMapPointToRiskCommand, ProjectDto } from '../../../api/api.generated.clients';
import StaticMapDialog from '../../Maps/StaticMapDialog';
import { LikelihoodScore, ConsequenceScore } from '../../Risks/ScoreEvaluation';
import { GetRiskColour, GetRiskSeverityLabel } from '../../Risks/RiskHelpers';
import Typography from '@mui/joy/Typography';
import RiskFilterSelectToolbar from '../../Risks/RiskFilterSelectToolbar';


export interface IRisksViewProps {
    project: ProjectDto,
    phaseId: string
    
}

export default function (props: IRisksViewProps) {
    const getRisksQuery = useGetRisks(props.project?.id ?? '');
    const [risks, setRisks] = React.useState<RiskItemDto[]>([]);
    const [filteredRisks, setFilteredRisks] = React.useState<RiskItemDto[]>([])
    const [selectedRisk, setSelectedRisk] = React.useState<RiskItemDto>();
    const [showEditForm, setShowEditForm] = React.useState<boolean>(false);
    const [showDeleteRiskForm, setShowDeleteRiskForm] = React.useState<boolean>(false);
    
    const [isMapDialogOpen, setIsMapDialogOpen] = React.useState<boolean>(false);
    const addMapMarker = useAddMapPoint();

    
    React.useEffect(() => {
        if (getRisksQuery.data && !getRisksQuery.error && !getRisksQuery.isPending) {
            setRisks(getRisksQuery.data.filter(x=>x.phaseId === props.phaseId));
        }
    }, [getRisksQuery.data, getRisksQuery.isPending, props.phaseId]);

    function onEditRisk(risk: RiskItemDto) {
        setSelectedRisk(risk);
        setShowEditForm(true);
    }

    function onCloseEditRiskForm() {
        setSelectedRisk(undefined);
        setShowEditForm(false);
    }

    function onDeleteRisk(risk: RiskItemDto) {
        setSelectedRisk(risk);
        setShowDeleteRiskForm(true);
    }

    function onCloseDeleteRiskForm() {
        setShowDeleteRiskForm(false);
        setSelectedRisk(undefined);
    }

    const tableStyle = {
        minWidth: 0,
        tableLayout: 'auto',
        width: '100%',
        '& tr > td': {
            verticalAlign: 'top'
        },
        '& tr > td:first-of-type': {
            position: 'sticky',
            left: 0,
            backgroundColor: 'white'
        },
        '& thead th:nth-of-type(5)': {
            minWidth: '500px',
            maxWidth: '500px'
        },
        '& thead th:nth-of-type(6)': {
            minWidth: '500px',
            maxWidth: '500px'
        },
        '& thead th:nth-of-type(7)': {
            minWidth: '500px',
            maxWidth: '500px'
        },
        '& thead th:nth-of-type(11)': {
            minWidth: '500px',
            maxWidth: '500px'
        },
        '& thead th:nth-of-type(12)': {
            minWidth: '500px',
            maxWidth: '500px'
        }
    }

    function onSaveMapMarker(risk: RiskItemDto, point: IMapPoint) {
        setSelectedRisk({
            ...selectedRisk,
            mapMarkers: [
                ...(selectedRisk?.mapMarkers || []),
                { centreLatitude: point.latitude, centreLongitude: point.longitude } as MapMarkerDto]
        });

        const newMarker = {
            riskId: risk.id,
            latitude: point.latitude,
            longitude: point.longitude
        } as AddMapPointToRiskCommand;

        addMapMarker(newMarker);
    }

    function onViewRiskMap(risk: RiskItemDto) {
        setSelectedRisk(risk);
        setIsMapDialogOpen(true);
    }

    function onMapDialogClose(): void {
        setIsMapDialogOpen(false);
        setSelectedRisk(undefined);
    }

    function onCloneRisk(risk: RiskItemDto){
        const clone = {...risk};
        clone.id = undefined;
        clone.referenceNumber = undefined;
        setSelectedRisk(clone);
        setShowEditForm(true);
    }

    if (!props.project)
        return <></>

    return (
        <Stack direction={'column'} spacing={1} sx={{ width: 'calc(100% - 32px)', maxHeight: '100%' }} >
            <Stack direction='row'>
                <ButtonGroup>
                    <Button onClick={() => setShowEditForm(true)}>Add Risk</Button>
                </ButtonGroup>
                <RiskFilterSelectToolbar project={props.project!} risks={risks} disabled={false} onRisksSet={(risks) => setFilteredRisks(risks)} />
            </Stack>
            <Box sx={{ overflow: 'auto', maxWidth: '100%' }}>
                <Table borderAxis='both' sx={tableStyle}>
                    <thead>
                        <tr>
                            <th></th>
                            <th><Typography level='title-md'>Reference Number</Typography></th>
                            <th><Typography level='title-md'>Discipline</Typography></th>
                            <th><Typography level='title-md'>Hazard Details</Typography></th>
                            <th><Typography level='title-md'>Hazard Cause</Typography></th>
                            <th><Typography level='title-md'>Hazard Consequence</Typography></th>
                            <th><Typography level='title-md'>Risk Owner</Typography></th>
                            <th><Typography level='title-md'>Likelihood Score</Typography></th>
                            <th><Typography level='title-md'>Consequence Score</Typography></th>
                            <th><Typography level='title-md'>Severity</Typography></th>
                            <th><Typography level='title-md'>Risk Elimination Measure</Typography></th>
                            <th><Typography level='title-md'>Mitigation in Design</Typography></th>
                            <th><Typography level='title-md'>Risk Eliminated?</Typography></th>
                            <th><Typography level='title-md'>Residual Likelihood Score</Typography></th>
                            <th><Typography level='title-md'>Residual Consequence Score</Typography></th>
                            <th><Typography level='title-md'>Residual Risk Owner</Typography></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredRisks.map((x, i) => {
                            return (<tr key={i}>
                                <td>
                                    <ButtonGroup size='sm'>
                                        <Tooltip title='Edit Risk'><IconButton size='sm' onClick={() => onEditRisk(x)}><Edit /></IconButton></Tooltip>
                                        <Tooltip title='Delete Risk'><IconButton size='sm' onClick={() => onDeleteRisk(x)}><Delete /></IconButton></Tooltip>
                                        <Tooltip title='Clone Risk'><IconButton onClick={()=>onCloneRisk(x)}><ContentCopyIcon/></IconButton></Tooltip>
                                        <Tooltip title='Add Map Marker to Risk'><AddItemToMapDialog
                                            initialLatitude={props.project?.centreLatitude || 0}
                                            initialLongitude={props.project?.centreLongitude || 0}
                                            initialZoom={props.project?.zoomLevel || 0}
                                            message={`Select Location to add a Map Marker for Risk: ${x.referenceNumber}`}
                                            title={x.referenceNumber}
                                            onSave={(m) => onSaveMapMarker(x, m)} /></Tooltip>
                                        <Tooltip title='View Risk Map Markers'><IconButton size='sm' onClick={() => onViewRiskMap(x)}><MapIcon /></IconButton></Tooltip>
                                    </ButtonGroup>
                                </td>
                                <td><Typography level='body-md'>{x.referenceNumber}</Typography></td>
                                <td><Typography level='body-md'>{x.discipline}</Typography></td>
                                <td><Typography level='body-md'>{x.hazardDetails}</Typography></td>
                                <td><Typography level='body-md'>{x.hazardCause}</Typography></td>
                                <td><Typography level='body-md'>{x.hazardConsequence}</Typography></td>
                                <td><Typography level='body-md'>{x.riskOwner}</Typography></td>
                                <td><Typography level='body-md'>{new LikelihoodScore(x.likelihoodScore).GetName()}</Typography></td>
                                <td><Typography level='body-md'>{new ConsequenceScore(x.consequenceScore).GetName()}</Typography></td>
                                <td><Typography level='body-md'>{GetRiskSeverityLabel(x)}</Typography></td>
                                <td><Typography level='body-md'>{x.riskEliminationMeasure}</Typography></td>
                                <td><Typography level='body-md'>{x.mitigationInDesign}</Typography></td>
                                <td><Typography level='body-md'>{x.riskEliminated ? 'true' : 'false'}</Typography></td>
                                <td><Typography level='body-md'>{new LikelihoodScore(x.residualLikelihoodScore).GetName()}</Typography></td>
                                <td><Typography level='body-md'>{new ConsequenceScore(x.residualConsequenceScore).GetName()}</Typography></td>
                                <td><Typography level='body-md'>{x.residualRiskOwner}</Typography></td>
                            </tr>)
                        })}
                    </tbody>
                </Table>
            </Box>
            <EditRiskForm isNew={!(selectedRisk?.id)}
                starterValues={!(selectedRisk?.id) ? selectedRisk : undefined}
                isOpen={showEditForm}
                onClose={() => onCloseEditRiskForm()}
                projectId={props.project.id ?? ''}
                riskId={selectedRisk?.id } 
                />
            <DeleteRiskDialog isOpen={showDeleteRiskForm}
                onClose={onCloseDeleteRiskForm}
                risk={selectedRisk!}
                projectId={props.project.id ?? ''} />
            <StaticMapDialog centreLatitude={props.project?.centreLatitude ?? 0}
                centreLongitude={props.project?.centreLongitude ?? 0}
                title={`Risk Reference#: ${props.project?.referenceNumber ?? ''}`}
                markers={selectedRisk?.mapMarkers?.map(m => ({ latitude: m.centreLatitude ?? 0, longitude: m.centreLongitude ?? 0, colour: GetRiskColour(selectedRisk) })) ?? []}
                zoomLevel={props.project?.zoomLevel ?? 1}
                isOpen={isMapDialogOpen}
                onClose={() => onMapDialogClose()} />
        </Stack>
    )
}