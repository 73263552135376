import React from 'react';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import GoogleMap, { IGoogleMapSnapshot, IMapMarker, IMapPoint } from './GoogleMap';
import Box from '@mui/joy/Box';
import { ProjectDto, } from '../../api/api.generated.clients';
import { useAddMapPoint, useDeleteMapPoint, SustainableOpportunityDto, AddMapPointToOpportunityCommand } from '../../api/useSustainability';

export interface IAddMapMarkerToOpportunityDialogProps {
    project: ProjectDto,
    opportunity?: SustainableOpportunityDto | undefined,
    onClose:() => void
}



export default function (props: IAddMapMarkerToOpportunityDialogProps) {
    const [marker, setMarker] = React.useState<IGoogleMapSnapshot | null>();
    const addMapMarker = useAddMapPoint();

    function onClick(snapshot: IGoogleMapSnapshot) {
        setMarker(snapshot);
    }

    function onSave(){
        const newMarker = {
            opportunityId: props.opportunity!.id,
            latitude: marker!.latitude,
            longitude: marker!.longitude
        } as AddMapPointToOpportunityCommand;

        addMapMarker(newMarker);
        setMarker(null);
        props.onClose();
    }

    
    return (<Stack>
        <Modal open={!!props.opportunity} onClose={() => props.onClose()}>
            <ModalDialog  sx={{width: '60%', height:'800%'}}>
                <DialogTitle>Add Map Marker</DialogTitle>
                <DialogContent><Typography>Place a Marker for Risk: {props.opportunity?.referenceNumber}</Typography></DialogContent>
                <Typography>Place a Marker for Risk: {props.opportunity?.referenceNumber}</Typography>

                <Box sx={{ height: '100%', width: '100%' }}>
                    <GoogleMap 
                        latitude={props.project.centreLatitude!} longitude={props.project.centreLongitude!} zoomLevel={props.project.zoomLevel!}
                        markers={!!marker ? [{latitude: marker.latitude, longitude: marker.longitude} as IMapMarker] : []}
                        onMapClick={onClick} />
                </Box>
                <Button variant='solid' disabled={!marker} onClick={onSave}>Save</Button>
            </ModalDialog>
        </Modal>
    </Stack>)
}