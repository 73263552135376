import React from 'react';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditRoles from './EditRolesForm';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import EditUserForm from './EditUserForm';
import ConfirmResetPasswordDialog from './ConfirmResetPasswordDialog';

export interface IUserContextMenuProps{
    userId: string
}

export default function(props: IUserContextMenuProps){
    const [showEditRoles, setShowEditRoles] = React.useState<boolean>(false);
    const [showDeleteUser, setShowDeleteUser] = React.useState<boolean>(false);
    const [showEditUser, setShowEditUser] = React.useState<boolean>(false);
    const [showResetPassword, setShowResetPassword] = React.useState<boolean>(false);

    return (
        <>
        <Dropdown>
            <MenuButton><MoreVertIcon/></MenuButton>
            <Menu>
                <MenuItem onClick={()=>setShowEditRoles(true)}><Typography>Assign Role</Typography></MenuItem>
                <MenuItem onClick={()=> setShowDeleteUser(true)}><Typography>Delete User</Typography></MenuItem>
                <MenuItem onClick={()=> setShowEditUser(true)}><Typography>Edit User</Typography></MenuItem>
                <MenuItem onClick={()=> setShowResetPassword(true)}><Typography>Reset Password</Typography></MenuItem>
            </Menu>
        </Dropdown>
        <EditRoles isOpen={showEditRoles} userId={props.userId} onClose={()=> setShowEditRoles(false)}/>
        <ConfirmDeleteDialog isOpen={showDeleteUser} userId={props.userId} onClose={()=>setShowDeleteUser(false)} />
        <EditUserForm isOpen={showEditUser} userId={props.userId} onClose={()=> setShowEditUser(false)}/>
        <ConfirmResetPasswordDialog isOpen={showResetPassword} onClose={()=> setShowResetPassword(false)} userId={props.userId}/>
        </>
    )
}