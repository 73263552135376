import React from 'react';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import { useSaveFile, FileParameter, SaveFolderCommand, FileDto } from '../../api/useFileSystem';
import { SaveFileCommand } from '../../api/api.generated.clients';


export interface IRenameFileDialogProps {
    isOpen: boolean,
    phaseId: string,
    parentfolderId?: string | undefined,
    onClose: () => void,
    file: FileDto,
}

export default function (props: IRenameFileDialogProps) {
    const [fileName, setFileName] = React.useState<string>(props.file.name || '');
    const [formError, setFormError] = React.useState<string | undefined>();
    const SaveFile = useSaveFile();

    const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!fileName)
            return;
        async function doWork(){
            var result = await SaveFile({ 
                parentFolderId: props.parentfolderId, 
                phaseId: props.phaseId, 
                fileName: !!props.file.extension ? `${fileName}.${props.file.extension}` : fileName,
                fileId: props.file.id,
                folderId: props.parentfolderId } as SaveFileCommand)

            if(result.succeeded)
                props.onClose();
            else{
                setFormError(result.errors?.join('. '))
            }
        }
        doWork();
    }

    const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value){
            setFileName(event.target.value);
        }
    }

    return (
        <Modal open={props.isOpen} onClose={props.onClose}>
            <ModalDialog>
                <form onSubmit={onFormSubmit}>
                    <Stack spacing={2} direction={'column'} gap={3}>
                        <FormControl>
                            <FormLabel>Enter the new File Name</FormLabel>
                            <Stack direction='row'><Input onChange={onChanged} autoFocus required  value={fileName}/>{!!props.file.extension && `.${props.file.extension}`}</Stack>
                            {!!formError && <FormHelperText>
                                {formError}
                            </FormHelperText>}
                        </FormControl>
                        <Button type="submit">Rename</Button>
                    </Stack>
                </form>
            </ModalDialog>
        </Modal>
    )
}