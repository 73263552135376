

import { Button, DialogContent, DialogTitle, Modal, ModalDialog, Select, Option, Stack, Table, Typography } from '@mui/joy'
import CheckBox from '@mui/joy/Checkbox';
import React from 'react'
import { AssignProjectUsersCommand, ProjectDto } from '../../api/useProjects'
import { GetUserItemDto, useGetUsersList } from '../../api/useUserAdmin';

export interface IAssignProjectUserFormProps {
    project: ProjectDto,
    onUpdated: (saveCommand: AssignProjectUsersCommand) => void
}

class PermissionUser {
    constructor(user: GetUserItemDto, enabled: boolean | undefined, permission: string | undefined) {
        this.user = user;
        this.enabled = enabled || false;
        this.permission = permission;
    }
    user: GetUserItemDto;
    enabled: boolean;
    permission: string | undefined;
}

export default function (props: IAssignProjectUserFormProps) {
    const [permissionUsers, setPermissionUsers] = React.useState<PermissionUser[]>([]);
    const [users, setUsers] = React.useState<GetUserItemDto[]>([]);
    const userQuery = useGetUsersList();
    const [assignUsersState, setAssignUsersState] = React.useState<AssignProjectUsersCommand>({});

    React.useEffect(() => {
        if (userQuery && userQuery.data && !userQuery.isPending) {
            setUsers(userQuery.data);
        }
    }, [userQuery.data, userQuery.isPending])

    React.useEffect(() => {
        if (users && props.project) {
            setPermissionUsers(users.map(x => new PermissionUser(x, props.project.projectUsers?.some(y => y.userId === x.userId), undefined)))
            setAssignUsersState({
                targetProjectId: props.project.id,
                mappings: props.project.projectUsers?.map(x => ({ userId: x.userId, canWrite: x.canWrite, isAdmin: x.isAdmin })) ?? []
            })
        }
    }, [users, props.project]);

    function onUserCheckClicked(user: PermissionUser, checked: boolean) {
        user.enabled = checked;
        setPermissionUsers([...permissionUsers.map(p => p.user.userId === user.user.userId ? user : p)])
        var assignedUsers = [...assignUsersState.mappings ?? []]
        const matchingAssignedUser = assignedUsers.find(x => x.userId === user.user.userId);
        if (checked && !matchingAssignedUser) {
            assignedUsers.push({
                userId: user.user.userId,
                canWrite: false,
                isAdmin: false
            })
        }
        else if (!checked && !!matchingAssignedUser) {
            const matchingIndex = assignedUsers.indexOf(matchingAssignedUser);
            if (matchingIndex > -1) {
                assignedUsers.splice(matchingIndex, 1)
            }
        }
        const newState = { ...assignUsersState, mappings: assignedUsers }
        setAssignUsersState(newState)
        props.onUpdated(newState)
    }

    function getRoleValue(user: PermissionUser) {
        const assignedUser = assignUsersState.mappings?.find(x => x.userId === user.user.userId);
        if (!assignedUser)
            return 3;

        if (assignedUser.isAdmin)
            return 1;

        if (assignedUser.canWrite)
            return 2;

        return 3;
    }

    function onUserRoleChange(user: PermissionUser, selectedValue: number) {
        if (!user)
            return;

        const assignedUser = assignUsersState.mappings?.find(x => x.userId === user.user.userId);
        if (!assignedUser)
            return;

        assignedUser.isAdmin = false;
        assignedUser.canWrite = false;

        if (selectedValue === 1) {
            assignedUser.isAdmin = true;
        }
        else if (selectedValue === 2) {
            assignedUser.canWrite = true;
        }
        const newState = {
            ...assignUsersState,
            mappings: [...assignUsersState.mappings?.filter(m => m.userId !== assignedUser.userId) ?? [], assignedUser]
        }
        setAssignUsersState(newState);
        props.onUpdated(newState)
    }



    return (
        <Stack>
            <Typography>Project User Permissions</Typography>
            <Stack spacing={2} justifyContent={'flex-start'}>
                <Table>
                    <thead>
                        <tr>
                            <th><Typography>Enabled</Typography></th>
                            <th><Typography>Name</Typography></th>
                            <th><Typography>Email</Typography></th>
                            <th><Typography>Permissions</Typography></th>
                        </tr>
                    </thead>
                    <tbody>
                        {permissionUsers.filter(u => !!u.user.userId).map(u => {
                            return (<tr>
                                <td><CheckBox checked={u.enabled} onChange={(e) => onUserCheckClicked(u, e.currentTarget.checked)} /></td>
                                <td><Typography>{u.user.firstName} {u.user.lastName} ({u.user.roles})</Typography></td>
                                <td><Typography>{u.user.email}</Typography></td>
                                <td>
                                    <Select disabled={!u.enabled} value={getRoleValue(u)} onChange={(e, v) => onUserRoleChange(u, v ?? 3)}>
                                        <Option value={1}>Project Lead</Option>
                                        <Option value={2}>Project Editor</Option>
                                        <Option value={3}>Project Reviewer</Option>
                                    </Select>
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </Table>
            </Stack>
        </Stack>
    )
}