import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import React, { ComponentType } from 'react';
import { ProjectDto, MapMarkerDto, RiskItemDto, SustainableOpportunityDto } from '../../../api/api.generated.clients';
import { useGetProject } from '../../../api/useProjects';
import GoogleMap, { IGoogleMapSnapshot, IMapMarker, IMapPoint, riskIcon, opportunityIcon } from '../../Maps/GoogleMap';
import { Button, ButtonGroup, Select, Option, Chip, IconButton, Checkbox, Typography } from '@mui/joy';
import SelectedRiskMarkerOverlay from '../../Risks/SelectedRiskMarkerOverlay';
import { GetRiskColour } from '../../Risks/RiskHelpers';
import { useGetRisks, useAddMapPoint, useDeleteMapPoint, AddMapPointToRiskCommand } from '../../../api/useRisks';
import { useGetOpportunityList, useDeleteMapPoint as useDeleteOpportunityMapPoint } from '../../../api/useSustainability';
import RiskFilterToolbar from '../../Risks/RiskFilterSelectToolbar';
import RiskFilterSelect from '../../Risks/RiskFilterSelect';
import OpportunityFilterSelect from '../../Sustainability/OpportunityFilterSelect';
import AddMapMarkerToRiskDialog from '../../Maps/AddMapMarkerToRiskDialog';
import AddMapMarkerToOpportunityDialog from '../../Maps/AddMapMarkerToOpportunityDialog';
import { GetDisciplines, GetPhases } from '../../Risks/RiskHelpers';
import EditRiskForm from '../../Risks/EditRiskForm';
import EditOpportunityForm from '../../Sustainability/EditOpportunityForm';
import OpportunityFilterSelectToolbar from '../../Sustainability/OpportunityFilterSelectToolbar';
import OpportunityMarkerOverlay from '../../Sustainability/OpportunityMarkerOverlay';
import FilterDrawer, { ICanResetFilters } from '../FilterDrawer';
import { IToolbarProps } from './FlexiFileView';


export interface IMapViewProps {
    project: ProjectDto,
    phaseId: string,
    Toolbar: ComponentType<IToolbarProps>,
}

export default function MapView(props: IMapViewProps) {
    const [allRisks, setAllRisks] = React.useState<RiskItemDto[]>([]);
    const [filteredRisks, setFilteredRisks] = React.useState<RiskItemDto[]>([]);
    const [riskMarkers, setRiskMarkers] = React.useState<IMapMarker[]>([]);
    const [opportunityMarkers, setOpportunityMarkers] = React.useState<IMapMarker[]>([]);
    const [selectedMarker, setSelectedMarker] = React.useState<MapMarkerDto | null>();
    const [isAddingRiskMarker, setIsAddingRiskMarker] = React.useState<boolean>(false);
    const [placeMarkerCallback, setPlaceMarkerCallback] = React.useState<(snapshot: IGoogleMapSnapshot) => void>();
    const [selectedRisk, setSelectedRisk] = React.useState<RiskItemDto | undefined>();
    const [showCreateRiskForm, setShowCreateRiskForm] = React.useState<boolean>(false);
    const [showCreateOpportunityForm, setShowCreateOpportunityForm] = React.useState<boolean>(false);
    const deleteMapMarker = useDeleteMapPoint();
    const deleteOpportunityMapMarker = useDeleteOpportunityMapPoint();
    // const riskQuery = useGetRisks(props.projectId);
    // const opportunitiesQuery = useGetOpportunityList(props.projectId);
    const [opportunities, setOpportunities] = React.useState<SustainableOpportunityDto[]>([]);
    const [filteredOpportunities, setFilteredOpportunities] = React.useState<SustainableOpportunityDto[]>([]);
    const [selectedOpportunity,setSelectedOpportunity] = React.useState<SustainableOpportunityDto | undefined>();
    const [showFilterDrawer, setShowFilterDrawer] = React.useState<boolean>(false);
    const filterRef = React.useRef<ICanResetFilters>(null);
    const [hasActiveFilters, setHasActiveFilters] = React.useState<boolean>(false);    

   

    React.useEffect(() => {
        loadMarkersForRisks();
    }, [filteredRisks]);

    React.useEffect(()=>{
        loadMarkersForOpportunities();
    }, [filteredOpportunities])

    if (!props.project || !props.project.centreLatitude || !props.project.centreLongitude || !props.project.zoomLevel)
        return <></>

    function doDeleteRiskMapMarker(marker: MapMarkerDto, risk: RiskItemDto) {
        async function doWork() {
            const result = await deleteMapMarker({ riskId: risk.id!, markerId: marker.id! })
            if (result.succeeded) {
                loadMarkersForRisks();
            }
        }
        doWork();
    }

    function doDeleteOpportunityMapMarker(marker: MapMarkerDto, opportunity: SustainableOpportunityDto) {
        async function doWork() {
            const result = await deleteOpportunityMapMarker({ opportunityId: opportunity.id!, markerId: marker.id! })
            if (result.succeeded) {
                loadMarkersForOpportunities();
            }
        }
        doWork();
    }



    function loadMarkersForRisks() {
        const risksAndMarkers = filteredRisks
            .map(r => r.mapMarkers?.map(m => ({ risk: r, markers: m })) ?? [])
            .reduce((prev, curr) => [...prev, ...curr], []);

        setRiskMarkers(risksAndMarkers.map(m => ({
            latitude: m.markers.centreLatitude,
            longitude: m.markers.centreLongitude,
            id: m.markers.id,
            colour: GetRiskColour(m.risk),
            overlayComponent: () => <SelectedRiskMarkerOverlay
                title={m.risk.referenceNumber}
                onDeleteMarker={() => doDeleteRiskMapMarker(m.markers, m.risk)} />,
            showOverlay: false,
            icon: riskIcon
        } as IMapMarker)))
    }

    function loadMarkersForOpportunities(){
        const opportunitiesAndMarkers = filteredOpportunities.map(o=> o.mapMarkers?.map(m=> ({opp: o, marker : m})) ?? [])
        .reduce((prev,curr)=>[...prev,...curr], []);

        const markers = opportunitiesAndMarkers.map(m=> ({
            latitude: m.marker.centreLatitude,
            longitude: m.marker.centreLongitude,
            id: m.marker.id,
            showOverlay: false,
            icon: opportunityIcon,
            colour: 'blue',
            overlayComponent: () => <OpportunityMarkerOverlay
                title={m.opp.referenceNumber}
                onDeleteMarker={() => doDeleteOpportunityMapMarker(m.marker, m.opp)} />,
        } as IMapMarker));

        setOpportunityMarkers(markers);
    }

    function onPlaceMarker(risk: RiskItemDto | undefined){
        console.log('place a risk marker')
        setSelectedRisk(risk);
    }



    return <Box sx={{ width: '100%', height: '100%' }}>
        <Stack direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} sx={{ width: '100%', height: '100%' }}>
        {props.project && <props.Toolbar 
                            phaseId={props.phaseId} 
                            project={props.project} 
                            onPlaceRiskMarker={(risk)=> setSelectedRisk(risk)} 
                            onPlaceOpportunityMarker={(op)=>setSelectedOpportunity(op)}
                            setMapMarkers={(mx)=> setRiskMarkers(mx)}
                            />}
            <Box sx={{ height: '100%', width: '100%', mt: '5px' }}>
                <GoogleMap
                    latitude={props.project.centreLatitude}
                    longitude={props.project.centreLongitude}
                    zoomLevel={props.project.zoomLevel}
                    onMapClick={(snp) => isAddingRiskMarker ? placeMarkerCallback?.(snp) : setSelectedMarker(undefined)}
                    markers={[...riskMarkers??[], ...opportunityMarkers].map(r => ({
                        ...r,
                        showOverlay: r === selectedMarker || r.showOverlay,
                        onClick: () => setSelectedMarker(r),
                    } as IMapMarker))}
                />
            </Box>
            <AddMapMarkerToRiskDialog
                onClose={() => setSelectedRisk(undefined)}
                project={props.project}
                risk={selectedRisk}
            />
            <AddMapMarkerToOpportunityDialog project={props.project} opportunity={selectedOpportunity} onClose={() => setSelectedOpportunity(undefined)}/>
        </Stack>
        {/* <EditRiskForm isNew={true} isOpen={showCreateRiskForm} projectId={props.project.id ?? ''} onClose={() => setShowCreateRiskForm(false)} />
        <EditOpportunityForm isNew={true} phaseId={props.phaseId ?? ''} isOpen={showCreateOpportunityForm} onClose={() => setShowCreateOpportunityForm(false)} projectId={props.project.id ?? ''} /> */}
        {/* <FilterDrawer 
            isOpen={showFilterDrawer} 
            onClose={() => setShowFilterDrawer(false)} 
            onRisksFiltered={r=> setAllRisks(r)}
            onOpportunitiesFiltered={o=>setOpportunities(o)}
            projectId={props.project.id ?? ''} 
            onActiveFiltersChanged={setHasActiveFilters}
            ref={filterRef}/> */}
    </Box>
}