import { CreateUserCommand, GetUserDto, GetUserItemDto, UpdateUserCommand, UserClient } from './api.generated.clients';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

export interface IQueryResponse<T> {
    isPending: boolean,
    data: T | undefined,
    error: Error
}

export interface IUseUserAdmin {
    getUserList: () => IQueryResponse<GetUserItemDto[]>,
    getUser: (userId: string) => IQueryResponse<GetUserDto>,
    createUser: (user: CreateUserCommand) => Promise<(string | undefined)[]>
    deleteUser: (userId: string) => Promise<void>,
    updateUser: (user: UpdateUserCommand) => Promise<string>
}

const client = new UserClient();

const useGetUsersList = () => {
    return useQuery({ queryKey: ['users'], queryFn: () => client.list() });
}

const useGetUser = (userId: string) => {
    return useQuery({ queryKey: ['user', userId], queryFn: () => client.userGet(userId) })
}



const useCreateUser = () => {
    const queryClient = useQueryClient();
    const createUserMutation = useMutation({
        mutationFn: async (user: CreateUserCommand) => {
            return client.userPost(user);
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['users'] })
        }
    })
    return createUserMutation.mutateAsync;
}



const useDeleteUser = () => {
    const queryClient = useQueryClient();
    const deleteUserMutation = useMutation({
        mutationFn: async (userId: string) => {
            return client.userDelete(userId);
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['users'] });
        }
    })
    return deleteUserMutation.mutateAsync;
}



const useUpdateUser = () => {
    const queryClient = useQueryClient();
    const editUserMutation = useMutation({
        mutationFn: (user: UpdateUserCommand) => {
            return client.update(user);
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['user', variables.userId] });
            queryClient.invalidateQueries({ queryKey: ['users'] })
        }
    });
    return editUserMutation.mutateAsync;
}

// return {
//     getUserList: getUsersList,
//     getUser: getUser,
//     createUser: createUser,
//     deleteUser: deleteUser,
//     updateUser: updateUser
// } as IUseUserAdmin

export type { CreateUserCommand, GetUserItemDto, GetUserDto, UpdateUserCommand }
export { useGetUsersList, useGetUser, useCreateUser, useDeleteUser, useUpdateUser }