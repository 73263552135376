import React from 'react';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import GoogleMap, { IGoogleMapSnapshot, IMapMarker, IMapPoint } from './GoogleMap';
import Box from '@mui/joy/Box';

export interface IAddItemToMapDialogProps {
    message?: string | undefined,
    title?: string | undefined,
    initialLatitude: number,
    initialLongitude: number,
    initialZoom: number,
    onSave: (point: IMapPoint) => void
}



export default function (props: IAddItemToMapDialogProps) {
    const [marker, setMarker] = React.useState<IGoogleMapSnapshot | null>();
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    function onClick(snapshot: IGoogleMapSnapshot) {
        setMarker(snapshot);
    }

    function onSave(){
        props.onSave(marker as IMapPoint);
        setMarker(null);
        setIsOpen(false);
    }

    function onButtonClick(e: React.MouseEvent<HTMLAnchorElement>){
        e.preventDefault();
        setIsOpen(true);
    }

    return (<Stack>
        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            <ModalDialog  sx={{width: '60%', height:'800%'}}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent><Typography>{props.message}</Typography></DialogContent>
                <Typography>{props.message}</Typography>

                <Box sx={{ height: '100%', width: '100%' }}>
                    <GoogleMap 
                        latitude={props.initialLatitude} longitude={props.initialLongitude} zoomLevel={props.initialZoom}
                        markers={!!marker ? [{latitude: marker.latitude, longitude: marker.longitude} as IMapMarker] : []}
                        onMapClick={onClick} />
                </Box>
                <Button variant='solid' disabled={!marker} onClick={onSave}>Save</Button>
            </ModalDialog>
        </Modal>
        <IconButton size='sm' onClick={(e) => onButtonClick(e)}><AddLocationAltIcon /></IconButton>
    </Stack>)
}