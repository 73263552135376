import React from 'react';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import styled from '@emotion/styled';
import Add from '@mui/icons-material/Add';
import { IGoogleMapSnapshot } from '../Maps/GoogleMap';
import { AssignProjectUsersCommand, ProjectUserDto, SaveProjectCommand } from '../../api/api.generated.clients';
import { useSaveProject, useAssignProjectUsers, ProjectPhaseDto, ProjectDto, useDeletePhase, useSavePhase, SaveProjectPhaseCommand } from '../../api/useProjects';
import AssignProjectUsersForm from './AssignProjectUsersForm';
import ProjectDetailsForm from './ProjectDetailsForm';
import ProjectMapSelector from './ProjectMapSelector';
import ManagePhasesView from './ManagePhasesView';
import Stepper from '@mui/joy/Stepper';
import Step from '@mui/joy/Step';
import StepIndicator from '@mui/joy/StepIndicator';
import { Typography } from '@mui/joy';


const AddButton = styled(Button)(({ theme }) => ({
    width: '100%',
    height: '100%',
    borderRadius: '10px'
}))

enum Page {
    Map = 1,
    Details = 2,
    AssignUsers = 3,
    ManagePhases = 4
}

export default function () {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [zoomLevel, setZoomLevel] = React.useState<number | undefined>(6);
    const [latitude, setLatitude] = React.useState<number | undefined>(-27);
    const [longitude, setLongitude] = React.useState<number | undefined>(152);
    const [assignUsersCommand, setAssignUsersCommand] = React.useState<AssignProjectUsersCommand>();
    const [currentPage, setCurrentPage] = React.useState<Page>(Page.Map);
    const [saveProjectCommand, setSaveProjectCommand] = React.useState<SaveProjectCommand>({})
    const [phases, setPhases] = React.useState<ProjectPhaseDto[]>([]);
    const [phasesToDelete, setPhasesToDelete] = React.useState<ProjectPhaseDto[]>([])
    const [project, setProject] = React.useState<ProjectDto>({})
    const [warningMessage, setWarningMessage] = React.useState<string | undefined>();
    const SaveProject = useSaveProject();
    const savePhase = useSavePhase();
    const assignUsers = useAssignProjectUsers();
    const deletePhase = useDeletePhase();

    const OnSaveClicked = () => {
        async function doWork() {
            if(!phases || phases.length === 0){
                setWarningMessage('At least 1 phase is required');
            }
            else if (saveProjectCommand ) {              
                const [head, ...tail] = phases;
                const projectId = await SaveProject({...saveProjectCommand, phaseName: head.phaseName, phaseStartDate: head.startDate, phaseDueDate: head.dueDate});
                if (!!assignUsersCommand) {
                    await assignUsers({ ...assignUsersCommand, targetProjectId: projectId })
                }
                if(!!tail && tail.length > 0){
                    tail.forEach(async p => {
                        await savePhase({...p, projectId: projectId, phaseId: p.id} as SaveProjectPhaseCommand);
                    });                    
                }
                // if(!!phasesToDelete){
                    phasesToDelete.forEach(async p=>{
                        await deletePhase({projectId: projectId, phaseId: p.id!})
                    })
                // }

                setPhasesToDelete([]);
                setCurrentPage(Page.Map);
                setIsOpen(false);
            }
            
            
        }
        doWork();
    }

    function onFormUpdated(cmd: SaveProjectCommand) {
        setSaveProjectCommand({...saveProjectCommand, ...cmd, zoomLevel: zoomLevel, centreLatitude: latitude, centreLongitude: longitude})
    }

    function onMapUpdated(snapshot: IGoogleMapSnapshot) {
        setZoomLevel(snapshot.zoomLevel);
        setLatitude(snapshot.latitude);
        setLongitude(snapshot.longitude);
    }
    
    function onClosing(){
        setCurrentPage(Page.Map);
        setIsOpen(false);
    }

    function onPhasesUpdate(phases: ProjectPhaseDto[]){
        setPhases(phases)
    }

    function onNextClick() {
        setCurrentPage(Math.min(currentPage + 1, Page.ManagePhases))
        setProject({...saveProjectCommand, centreLatitude: latitude, centreLongitude: longitude} as ProjectDto);
    }

    function onPrevClick() {
        setCurrentPage(Math.max(currentPage - 1, Page.Map));
        setProject({...saveProjectCommand} as ProjectDto);
    }

    return (
        <Stack>
            <AddButton sx={{ width: '100px', height: '100px' }} onClick={() => setIsOpen(true)} startDecorator={<Add />}>Create New Project</AddButton>
            <Stack >
                <Modal open={isOpen} onClose={() => onClosing()}>
                    <ModalDialog sx={{ width: '800px', height: '800px', overflow: 'auto' }}>
                        <DialogTitle>Create new project</DialogTitle>
                        <Stack sx={{flexGrow: 2}} spacing={2}>
                            <Stepper>
                                <Step orientation='vertical' indicator={<StepIndicator variant={ currentPage == 1 ? 'solid' : 'outlined'}>1</StepIndicator>}>Choose Location</Step>
                                <Step orientation='vertical' indicator={<StepIndicator variant={ currentPage == 2 ? 'solid' : 'outlined'}>2</StepIndicator>}>Details</Step>
                                <Step  orientation='vertical' indicator={<StepIndicator variant={ currentPage == 3 ? 'solid' : 'outlined'}>3</StepIndicator>}>Assign Users</Step>
                                <Step orientation='vertical' indicator={<StepIndicator variant={ currentPage == 4 ? 'solid' : 'outlined'}>4</StepIndicator>}>Phases</Step>
                            </Stepper>
                        { currentPage == Page.Map && <ProjectMapSelector onUpdated={onMapUpdated} zoomLevel={project.zoomLevel} latitude={project.centreLatitude} longitude={project.centreLongitude}/> }
                        { currentPage == Page.Details && <ProjectDetailsForm project={project} onUpdated={onFormUpdated} /> }
                        { currentPage == Page.AssignUsers && <AssignProjectUsersForm
                            onUpdated={cmd => setAssignUsersCommand(cmd)}
                            project={{ projectName: saveProjectCommand.projectName, projectUsers: assignUsersCommand?.mappings?.map(x => ({ userId: x.userId, canWrite: x.canWrite, isAdmin: x.isAdmin } as ProjectUserDto)) } as ProjectDto} />}
                        { currentPage == Page.ManagePhases && <ManagePhasesView phases={[]} onUpdated={onPhasesUpdate} onDelete={p=>setPhasesToDelete([...phasesToDelete, p])}/>}
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Button onClick={onPrevClick} variant='soft'>Previous</Button>
                            { currentPage != Page.ManagePhases && <Button onClick={onNextClick}>Next</Button> }
                            { currentPage == Page.ManagePhases && <Button onClick={OnSaveClicked}>Save</Button> }                            
                        </Stack>                  
                        { warningMessage && <Typography color='warning'>{warningMessage}</Typography> }
                    </ModalDialog>
                </Modal>
            </Stack>
        </Stack>

    )
}