import React from 'react';
import { Button, IconButton, Input, Stack, Typography } from '@mui/joy';
import Tabs from '@mui/joy/Tabs';
import Tab from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import CheckIcon from '@mui/icons-material/Check';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { IdGenerationType, useGetIdTemplate, useSaveIdTemplate, IdGenerationItemDto, SaveIdTemplateCommand } from '../../api/useIdTemplate';

export interface IRefNumGenSettingsProps {
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatAdapter = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatAdapter(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }} allowLeadingZeros defaultValue={1} 
                valueIsNumericString
            //   prefix="$"
            />
        );
    },
);

function RefNumConfigForm({ template }: { template: IdGenerationItemDto | undefined }) {
    const [item, setItem] = React.useState<IdGenerationItemDto>(template ?? {});
    const saveIdTemplate = useSaveIdTemplate();
    const [validationMessage, setValidationMessage] = React.useState<string>();

    React.useEffect(()=>{
        const newItem = {...template};
        newItem.startingNumber = Math.max((newItem.lastAllocatedNumber ?? 1) + 1, newItem.startingNumber ?? 1)
        setItem(newItem);;
    },[template])

    function getDateText(){
        const date = new Date();
        return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    }

    function generateSample(){
        const date = new Date();
        var text = item.template ?? '';

        text = text.replace("${Number}", (item?.startingNumber ?? 1).toString())
        text = text.replace("${Date}", `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`);
        text = text.replace("${Year}", date.getFullYear().toString());
        text = text.replace("${Month}", date.getMonth().toString());
        text = text.replace("${Day}", date.getDate().toString());

        return text;
    }

    function updateStartingNumber(e : React.ChangeEvent<HTMLInputElement>){
        let newValue = parseInt(e.target.value);
        if(newValue <= (item.lastAllocatedNumber ?? 0)){
            newValue = item.lastAllocatedNumber ?? 0
            newValue++;
            setValidationMessage('Starting Number must be greater than the last allocated number')
        }
        setItem({ ...item, startingNumber: newValue})
    }

    function onSaveIdTemplate(){
        async function doWork(){
            const cmd = {
                templateType : item.generationType,
                startingNumber: item.startingNumber,
                template: item.template
            } as SaveIdTemplateCommand;

            const res = await saveIdTemplate(cmd);
            if(!res.succeeded){
                setValidationMessage(res.errors?.reduce((agg, curr)=> `${agg}, ${curr}`))
            }
        }
        doWork();
    }

    return (
        <Stack direction={'row'} sx={{ width: '100%' }}>
            <Stack direction='column' sx={{ width: '30%' }}>
                <Stack alignItems={'flex-start'} sx={{ mb: '10px' }}>
                    <Typography level='h3'>
                        Reference Number Template
                    </Typography>
                    <Input sx={{ width: '100%'}} value={item?.template} onChange={(e) => setItem({ ...item ?? {}, template: e.currentTarget.value })} />
                </Stack>
                <Stack alignItems={'flex-start'} sx={{ mb: '10px' }}>
                    <Typography level='h3'>
                        Counter Starting Number
                    </Typography>
                    <Input slotProps={{ input: { component: NumericFormatAdapter } }} sx={{ width: '100%'}} value={item?.startingNumber ?? 1} onChange={updateStartingNumber} />
                </Stack>
                <Stack alignItems={'flex-start'} sx={{ mb: '10px' }}>
                    <Typography level='h3'>
                        Next Generated Reference Number:
                    </Typography>
                    <Typography fontWeight={'bold'}>{generateSample()}</Typography>
                </Stack>
                {validationMessage && <Stack alignItems={'flex-start'} sx={{ mb: '10px'}}>
                        <Typography color='warning'>{validationMessage}</Typography>
                    </Stack>}
                <Stack alignItems={'flex-start'} sx={{ mb: '10px' }}>
                    <Button onClick={onSaveIdTemplate}>Save</Button>
                </Stack>
            </Stack>
            <Stack direction={'column'} alignItems={'flex-start'} sx={{ pl: '20px'}}>
                <Typography>Build a template string using static text and the below tokens to generate dynamic text</Typography>
                <ul>
                    <li><Stack direction='row'><Typography fontWeight={'bold'}>{"${Number}"}</Typography> - include an autoincrementing number (Must be used exactly once)</Stack></li>
                    <li><Stack direction='row'><Typography fontWeight={'bold'}>{"${Date}"}</Typography> - include a short date (YYYY-MM-DD)</Stack></li>
                    <li><Stack direction='row'><Typography fontWeight={'bold'}>{"${Year}"}</Typography> - include the current year</Stack></li>
                    <li><Stack direction='row'><Typography fontWeight={'bold'}>{"${Month}"}</Typography> - include the current month in 2 digit numeric form</Stack></li>
                    <li><Stack direction='row'><Typography fontWeight={'bold'}>{"${Day}"}</Typography> - include the current day of the month in 2 digit numeric form</Stack></li>
                </ul>
                <Typography fontStyle={'italic'}>e.g. "PROJ-{"${Date}-${Number}"}" would generate a reference number that looks like "PROJ-{getDateText()}-1"</Typography>
            </Stack>
        </Stack>
    )
}

export default function (props: IRefNumGenSettingsProps) {
    const getProjectIdTemplateQuery = useGetIdTemplate(IdGenerationType.Project);
    const getRiskIdTemplateQuery = useGetIdTemplate(IdGenerationType.Risk);
    const getOpportunityQuery = useGetIdTemplate(IdGenerationType.Opportunity);
    const [projectTemplate, setProjectTemplate] = React.useState<IdGenerationItemDto>();
    const [riskTemplate, setRiskTemplate] = React.useState<IdGenerationItemDto>();
    const [opportunityTemplate, setOpportunityTemplate] = React.useState<IdGenerationItemDto>();

    React.useEffect(() => {
        if (!getProjectIdTemplateQuery.isPending && !getProjectIdTemplateQuery.isError && getProjectIdTemplateQuery.data && getProjectIdTemplateQuery.data.succeeded) {
            setProjectTemplate(getProjectIdTemplateQuery.data.data)
        }
    }, [getProjectIdTemplateQuery.data, getProjectIdTemplateQuery.isPending, getProjectIdTemplateQuery.isError])

    React.useEffect(() => {
        if (!getRiskIdTemplateQuery.isPending && !getRiskIdTemplateQuery.isError && getRiskIdTemplateQuery.data && getRiskIdTemplateQuery.data.succeeded) {
            setRiskTemplate(getRiskIdTemplateQuery.data.data)
        }
    }, [getRiskIdTemplateQuery.data, getRiskIdTemplateQuery.isPending, getRiskIdTemplateQuery.isError])

    React.useEffect(() => {
        if (!getOpportunityQuery.isPending && !getOpportunityQuery.isError && getOpportunityQuery.data && getOpportunityQuery.data.succeeded) {
            setOpportunityTemplate(getOpportunityQuery.data.data)
        }
    }, [getOpportunityQuery.data, getOpportunityQuery.isPending, getOpportunityQuery.isError])



    return (
        <>
            <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} sx={{ mb: '10px' }}>
                <Typography level='h2'>Reference Number Auto-Generation</Typography>
                <p>
                    <Typography level='body-md'>
                        In this section you can configure a template to be used for auto-generating the reference number for projects, risks and opportunities. A template can contain static text, auto-incrementing numbers and date parts.
                    </Typography>
                </p>
            </Stack>


            <Tabs orientation='vertical'>
                <TabList>
                    <Tab>
                        <Typography>Projects</Typography>
                    </Tab>
                    <Tab>
                        <Typography>Risks</Typography>
                    </Tab>
                    <Tab>
                        <Typography >Sustainability Opportunity</Typography>
                    </Tab>
                </TabList>
                <TabPanel value={0}>
                    <Stack alignItems={'flex-start'}>
                        <RefNumConfigForm template={projectTemplate} />
                    </Stack>
                </TabPanel>
                <TabPanel value={1}>
                    <Stack alignItems={'flex-start'}>
                        <RefNumConfigForm template={riskTemplate} />
                    </Stack>
                </TabPanel>
                <TabPanel value={2}>
                    <Stack alignItems={'flex-start'}>
                        <RefNumConfigForm template={opportunityTemplate} />
                    </Stack>
                </TabPanel>
            </Tabs>
        </>
    )
}